/**
 * Language Select Dropdown
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Dropdown, DropdownMenu, DropdownToggle} from 'reactstrap';
import {Scrollbars} from 'react-custom-scrollbars';
import Tooltip from '@material-ui/core/Tooltip';
// actions
import {setLanguage} from '../actions/settingActions';

import T from '../lang/IntlMessages';

class LanguageProvider extends Component {

    state = {
        langDropdownOpen: false
    };

    // function to toggle dropdown menu
    toggle = () => {
        this.setState({
            langDropdownOpen: !this.state.langDropdownOpen
        });
    };

    // on change language
    onChangeLanguage(lang) {
        if(!!this.props.onLanguageChange) {
            this.props.onLanguageChange(lang.locale)
        }
        this.setState({langDropdownOpen: false});
        this.props.setLanguage(lang);
    }

    render() {
        const {locale, languages} = this.props;
        return (
            <Dropdown nav className="list-inline-item language-dropdown tour-step-5"
                      isOpen={this.state.langDropdownOpen} toggle={this.toggle}>
                <DropdownToggle caret nav className="header-icon language-icon">
                    <Tooltip title={<T id="languages"/>} placement="bottom">
                        <span style={{color: '#FAFAFA'}}>{locale.icon} <T id={locale.languageId.toLowerCase()}/></span>
                    </Tooltip>
                </DropdownToggle>
                <DropdownMenu>
                    <div className="dropdown-content">
                        <Scrollbars className="rct-scroll" autoHeight autoHeightMax={280}>
                            <ul className="list-unstyled mb-0 dropdown-list">
                                {languages.map((language, key) => (
                                    <li key={key} onClick={() => this.onChangeLanguage(language)}>
                                        <span style={{paddingLeft: '10px', color: '#053776', 'cursor': 'pointer'}}>
                                            {language.icon} <T id={language.languageId}/>
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </Scrollbars>
                    </div>
                </DropdownMenu>
            </Dropdown>
        );
    }
}

// map state to props
const mapStateToProps = ({settings}) => {
    return settings
};

export default connect(mapStateToProps, {
    setLanguage
})(LanguageProvider);
