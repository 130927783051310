import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import {onSectionLoad} from './Events';

import "./InformationDetails.css";

import HistoricalReview from "./information/HistoricalReview";
import MissionVision from "./information/MissionVision";
import StrategicObjectives from "./information/StrategicObjectives";
import RegulatoryFramework from "./information/RegulatoryFramework";
import OrganizationChart from "./information/OrganizationChart";
import Authorities from "./information/Authorities";
import AdvisoryBoard from "./information/AdvisoryBoard";

class InformationDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
        };

        this.myRef = React.createRef()
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.id !== prevProps.match.params.id)
            this.setState({ id: this.props.match.params.id });
    }

    getView = () => {
        onSectionLoad('information');
        
        switch (this.state.id) {
            case "historical_review":
                return <HistoricalReview />;
            case "mission_vision":
                return <MissionVision />;
            case "strategic_objectives":
                return <StrategicObjectives />;
            case "regulatory_framework":
                return <RegulatoryFramework />;
            case "organization_chart":
                return <OrganizationChart />;
            case "authorities":
                return <Authorities />;
            case "advisory_board":
                return <AdvisoryBoard />;
            default:
                return null;
        }
    };

    render() {
        return <div ref={this.myRef} className="information-details">{this.getView()}</div>;
    }
}

const styles = () => ({});

const mapStateToProps = ({
    settings: {
        locale: { locale }
    }
}) => {
    return { locale };
};

export default connect(mapStateToProps)(withStyles(styles)(InformationDetails));
