import React, {Component} from 'react';
import {withStyles} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import IntlMessages from '../lang/IntlMessages';
import classnames from 'classnames';
import {getContent, getCover} from "../services/content-service";
import {connect} from "react-redux";

class NewsDetail extends Component {

    state = {
        content: null
    };

    loadNews = (id) => {
        getContent(id).then(content => this.setState({content}))
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.loadNews(this.props.match.params.id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.id !== prevProps.match.params.id)
            this.loadNews(this.props.match.params.id);
    }

    render() {
        const {classes, locale} = this.props;
        const {content} = this.state;

        if (content === null) return (
            <Typography variant="h6" color="textPrimary" className={classes.title} gutterBottom>
                <IntlMessages id='loading'/>...
            </Typography>
        );

        const {title: {[locale]: title}, content: {[locale]: body}, abstract, id} = content;
        const description = abstract ? abstract[locale] : '';
        return (
            <div className={"container pt-5 pb-2"}>
                <Typography variant="h6" color="textPrimary" className={classes.title} gutterBottom>
                    {title}
                </Typography>

                <Typography component="p" color="textSecondary" className={classnames('text-justify', classes.resume)}
                            gutterBottom>
                    {description}
                </Typography>

                <IntlMessages id="cover">{text => <img className='img-fluid pt-2' alt={text} src={getCover(id)}
                                                       width="100%" height={300}/>}</IntlMessages>
                <div className="body-content pt-4">
                    {
                        (body.blocks || []).map((block, i) => (
                            <DisplayBlock key={'block_' + i} {...block} classes={classes}/>
                        ))
                    }
                </div>
            </div>
        );
    }
}

const DisplayBlock = ({type, data}) => {

    switch (type) {

        case "delimiter":
            return <hr/>;
        case "header":
            return <Typography variant="h6" color="textPrimary" gutterBottom
                               dangerouslySetInnerHTML={{__html: data.text}}/>;
        case "paragraph":
            return <Typography variant="body1" color="textSecondary" gutterBottom
                               dangerouslySetInnerHTML={{__html: data.text}}/>;
        case "list":
            if (data.style === 'ordered')
                return <ol>{(data.items || []).map(item => (<li dangerouslySetInnerHTML={{__html: item}}/>))}</ol>;
            else
                return <ul>{(data.items || []).map(item => (<li dangerouslySetInnerHTML={{__html: item}}/>))}</ul>;
        case "table":
            return (
                <table className={'table-bordered '} style={{width: '100%'}}>
                    {(data.content || []).map(row => (
                        <tr>
                            {(row || []).map(column => <td dangerouslySetInnerHTML={{__html: column}}/>)}
                        </tr>
                    ))}
                </table>
            );
        default:
            return <div/>;

    }
};

const styles = () => ({
    title: {
        fontSize: 18,
        color: '#A5A4A4',
    },
    resume: {
        fontSize: 14,
        color: '#A5A4A4',
        fontStyle: 'italic',
        fontWeight: '100',
    },
    description: {
        fontSize: 12,
        color: '#A5A4A4',
    },
});


const mapStateToProps = ({settings: {locale: {locale}}}) => {
    return {locale};
};
export default connect(mapStateToProps)(withStyles(styles)(NewsDetail));
