import React from 'react';
import Banner from "./Banner";
export default () => {
    return (
        <div>
            <Banner />
            <MissionVisionES/>
        </div>
    );
}

const MissionVisionES = () => {
    return <div>
        <h1>MISIÓN/VISIÓN</h1>

        <h2>MISIÓN</h2>
        <p>
            Un Sistema Especial de Puerto Libre dirigido a promover la inversión privada, el turismo y la cultura dentro del casco de la ciudad de Colón, para dinamizar de forma sustentable la economía de la provincia y el desarrollo humano de los colonenses y residentes.
        </p>

        <h2>VISIÓN</h2>
        <p>
            Desarrollar una zona de comercio en el marco de incentivos especiales que ayuden a facilitar y fortalecer el comercio al por menor, que funcione como vitrina de compras para el turismo local e internacional y resalte nuestra cultura potenciando los talentos de los ciudadanos.
        </p>
    </div>
};
