import React, { Component, Fragment } from 'react';

import HomeHeader from '../components/home-header';
import HomeBody from '../components/home-body';

import { announcements, events, newsList } from '../constants/data';
import { Route } from 'react-router';
import PurchaserLayout from './PurchaserLayout';
import PurchaserDetail from './PurchaserDetail';
import MerchantLayout from './MerchantLayout';
import { getContents } from '../services/content-service';
import { connect } from 'react-redux';

import { onSectionLoad } from './Events';

const CONTENT_KEY = 'CONTENT_KEY';

class Home extends Component {
	state = {
		announcements,
		events,
		newsList,
		hide: false,
	};

	setContent = (content) => {
		if (!content) return;
		this.setState({
			newsList: content.filter((item) => item.type === 'news'),
			events: content.filter((item) => item.type === 'event'),
			announcements: content.filter(
				(item) => item.type === 'announcement'
			),
		});
	};

	componentDidMount() {
		window.scrollTo(0, 0);
		const prevContent = localStorage.getItem(CONTENT_KEY);
		this.setContent(JSON.parse(prevContent));

		getContents()
			.then((content) => {
				localStorage.setItem(CONTENT_KEY, JSON.stringify(content));
				this.setContent(content);
			})
			.catch((e) => console.log('error', e));
	}

	render() {
		onSectionLoad('home');
		const showHomeBody = window.location.pathname === '/home';

		return (
			<Fragment>
				<Route
					path={`${this.props.match.path}/purchaser`}
					exact
					render={(props) => {
						return <PurchaserLayout {...props} />;
					}}
				/>
				<Route
					path={`${this.props.match.path}/purchaser/:id`}
					render={(props) => <PurchaserDetail {...props} />}
				/>
				<Route
					path={`${this.props.match.path}/merchant`}
					render={(props) => <MerchantLayout {...props} />}
				/>
				{showHomeBody ? <HomeHeader /> : null}
				{showHomeBody ? (
					<HomeBody
						announcements={this.state.announcements}
						events={this.state.events}
						newsList={this.state.newsList}
					/>
				) : null}
			</Fragment>
		);
	}
}

export default connect((state) => state)(Home);
