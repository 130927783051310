import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import classnames from 'classnames';
import moment from 'moment';
import T from '../lang/IntlMessages';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {getThumbnail} from "../services/content-service";

const styles = () => ({
    card: {
        borderColor: '#707070'
    },

    title: {
        color: '#C4C4C4',
        backgroundColor: 'white',
        fontSize: 16,
        fontWeight: 400,
        borderColor: '#707070'
    },

    footer: {
        color: '#C4C4C4',
        fontSize: 8,
        fontWeight: 400
    },

    description: {
        color: '#C4C4C4',
        fontSize: 10,
        fontWeight: 400
    },
    item: {
        borderColor: '#707070',
        minHeight: 80
    },
    itemFooter: {
        borderColor: '#707070',
        minHeight: 30,
        alignItems: 'center'
    },
    img: {
        maxHeight: '165px',
        maxWidth: '200px',
        height: '165px',
        width: '200px',
    },
    block: {width: "100%"}
});

const HomeNews = (props) => {
    const {locale, title: {[locale]: title}, abstract, classes, link, startDate, type, id, followLink} = props;
    const description = abstract ? abstract[locale] : '';
    const date = startDate ? moment(startDate).format("DD/MM/YYYY") : '';

    return (
        <div className={classnames("card rounded-0 flex-row", classes.card)}>
            {(type === 'news' || type === 'event') && (
                <Link to={'./' + type + "/" + id} params={props}>
                    <div className="d-flex justify-content-center border-0">
                        <T id="thumbnail">{text => <img src={getThumbnail(id)} alt={text} className={classes.img}/>}</T>
                    </div>
                </Link>
            )}

            <div className={classnames("card-block", classes.block)}>
                <div className={classnames("card-header pl-2", classes.title)}>
                    {(type === 'news' || type === 'event') && !followLink ? (
                        <Link to={'./' + type + "/" + id} params={props}>
                            {title}
                        </Link>
                    ) : link ? <a href={link}>{title}</a> : title}
                </div>
                <ul className="list-group list-group-flush">
                    <li
                        className={classnames("d-flex flex-column justify-content-between list-group-item p-2 text-justify", classes.item)}>
                        <p className={classes.description} dangerouslySetInnerHTML={{__html: description}}/>
                    </li>
                    <li
                        className={classnames("d-flex justify-content-between list-group-item pl-2 pt-0 pb-0 pr-1", classes.itemFooter)}>
                        <Typography component="p" className={classnames("p", classes.footer)}>
                            <T id='published'/> - {date}
                        </Typography>
                        {(type === 'news' || type === 'event') && !followLink ? (
                            <Link to={'./' + type + "/" + id} params={props}>
                                <ArrowForwardIcon fontSize={"small"}/>
                            </Link>
                        ) : (
                            <Link to={link} params={props}>
                                <ArrowForwardIcon fontSize={"small"}/>
                            </Link>
                        )}
                    </li>
                </ul>
            </div>
        </div>
    )
};

const mapStateToProps = ({settings: {locale: {locale}}}) => {
    return {locale};
};
export default connect(mapStateToProps)(withStyles(styles)(HomeNews));
