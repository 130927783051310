/**
 * App Config File
 */
const AppConfig = {
    // appLogo: require('Assets/img/site-logo.png'),             // App Logo
    brandName: 'CPL',                                           // Brand Name
    navCollapsed: false,                                        // Sidebar collapse
    boxLayout: false,                                           // Box Layout
    isDarkSidenav: true,                                        // Set true to dark sidebar
    debug: true,
    locale: {
        languageId: 'spanish',
        locale: 'es',
        name: 'Spanish',
        icon: '🇵🇦',
    },
    copyRightText: 'CPL © 2019 All Rights Reserved.',           // Copy Right Text
    // light theme colors
    themeColors: {
        'primary': '#5D92F4',
        'secondary': '#677080',
        'success': '#00D014',
        'danger': '#FF3739',
        'warning': '#FFB70F',
        'info': '#00D0BD',
        'dark': '#464D69',
        'default': '#FAFAFA',
        'greyLighten': '#A5A7B2',
        'grey': '#677080',
        'white': '#FFFFFF',
        'purple': '#896BD6',
        'yellow': '#D46B08'
    },
    // dark theme colors
    darkThemeColors: {
        darkBgColor: '#424242'
    },
    languages: [
        {
            languageId: 'english',
            locale: 'en',
            name: 'English',
            icon: '🇺🇸',
        },
        {
            languageId: 'spanish',
            locale: 'es',
            name: 'Spanish',
            icon: '🇵🇦',
        },/*
      {
         languageId: 'chinese',
         locale: 'zh',
         name: 'Chinese',
         icon: 'zh',
      },
      {
         languageId: 'russian',
         locale: 'ru',
         name: 'Russian',
         icon: 'ru',
      },
      {
         languageId: 'hebrew',
         locale: 'he',
         name: 'Hebrew',
         icon: 'he',
      },
      {
         languageId: 'french',
         locale: 'fr',
         name: 'French',
         icon: 'fr',
      },
      {
         languageId: 'saudi-arabia',
         locale: 'ar',
         name: 'Arabic',
         icon: 'ar',
      },
      {
         languageId: 'german',
         locale: 'de',
         name: 'German',
         icon: 'de',
      },

      {
         languageId: 'japanese',
         locale: 'ja',
         name: 'Japanese',
         icon: 'ja',
      },
      {
         languageId: 'korean',
         locale: 'ko',
         name: 'Korean',
         icon: 'ko',
      },
      {
         languageId: 'italian',
         locale: 'it',
         name: 'Italian',
         icon: 'it',
      },
      {
         languageId: 'hungarian',
         locale: 'hu',
         name: 'Hungarian',
         icon: 'hu',
      }*/
    ],
    // apiPath: 'http://ec2-18-218-98-33.us-east-2.compute.amazonaws.com'
    apiPath: 'https://api-cpl.mici.gob.pa'
};

export default AppConfig;
