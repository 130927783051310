import React from 'react';
import Banner from './Banner';

import OrganizationChartImageES from './organization_chart.jpg';
import OrganizationChartImageES2 from './organization_chart_2.jpg';
export default () => {
	return (
		<div>
			<Banner />
			<OrganizationChartES />
		</div>
	);
};

const OrganizationChartES = () => {
	return (
		<div className="org-chart-container">
			<h1>Organigrama</h1>
			<div>
				<img src={OrganizationChartImageES} alt="Organization Chart" />
				<img src={OrganizationChartImageES2} alt="Organization Chart" />
			</div>
		</div>
	);
};
