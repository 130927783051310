import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import IntlMessages from '../lang/IntlMessages';
import { findPurchaserById } from '../services/purchaser-service';
import { Link } from 'react-router-dom';
import * as qs from 'query-string';
import * as moment from 'moment';
import {
	DOC_TYPES,
	ERROR_PURCHASER_NOT_FOUND,
	PURCHASER_TYPE_CITIZEN,
	PURCHASER_TYPE_RESIDENT,
	PURCHASER_TYPE_TOURIST,
} from '../constants/data';
import { NotificationManager } from 'react-notifications';
import { Redirect } from 'react-router';

class PurchaserDetail extends Component {
	state = {
		purchaser: null,
		redirect: false,
		loading: true,
	};

	loadPurchaser = () => {
		const params = qs.parse(this.props.location.search);
		findPurchaserById(this.props.match.params.id, params.expireDate)
			.then((res) => {
				let data = res.data;
				const purchaser = {
					...data,
					birthDate: !!moment(data.birthDate).isValid()
						? moment.utc(data.birthDate).format('YYYY-MM-DD')
						: null,
					expireDate: moment
						.utc(data.expireDate)
						.format('YYYY-MM-DD'),
					createdAt: moment.utc(data.createdAt).format('YYYY-MM-DD'),
				};
				if (!!purchaser.sex) {
					purchaser.sex = `${purchaser.sex}`.trim().toLowerCase();
				}
				this.setState({
					purchaser: purchaser,
				});
			})
			.catch((e) => {
				if (
					typeof e.response !== 'undefined' &&
					e.response.data.code === ERROR_PURCHASER_NOT_FOUND
				) {
					NotificationManager.error('Comprador no encontrado');
					this.setState({ redirect: true });
				} else NotificationManager.error('Error desconocido');
			})
			.finally(() => this.setState({ loading: false }));
	};

	componentDidMount() {
		this.loadPurchaser();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.match.params.id !== prevProps.match.params.id)
			this.loadPurchaser();
	}

	render() {
		const { classes } = this.props;
		const { purchaser, redirect } = this.state;

		if (redirect) {
			return <Redirect to="/home/purchaser" />;
		}

		if (purchaser === null)
			return (
				<Typography
					variant="h6"
					color="textPrimary"
					align={'center'}
					className={classes.title}
					gutterBottom
				>
					<IntlMessages id="loading" />
					...
				</Typography>
			);

		let birthDateComponent = null;
		if (!!purchaser.birthDate) {
			birthDateComponent = (
				<div className="form-group row">
					<label
						htmlFor="birthdate"
						className="col-sm-4 col-form-label"
					>
						<IntlMessages id="birthdate" />
					</label>
					<div className="col-sm-8">
						<input
							type="date"
							id="birthdate"
							disabled
							defaultValue={purchaser.birthDate}
							className="form-control"
						/>
					</div>
				</div>
			);
		}

		return (
			<div className={'container pt-5 pb-2'}>
				<Typography
					variant="h6"
					color="textPrimary"
					className={classes.title}
					gutterBottom
				>
					<IntlMessages id="purchaserDetails" />
					...
				</Typography>
				<div className="form-group row">
					<label htmlFor="type" className="col-sm-4">
						<IntlMessages id="docType" />
					</label>
					<div className="col-sm-8">
						<select
							required
							id="identificationType"
							name="identificationType"
							disabled
							defaultValue={purchaser.identificationType}
							className="form-control"
						>
							<IntlMessages id="choose">
								{(text) => (
									<option value={''} disabled>
										{text}...
									</option>
								)}
							</IntlMessages>
							{DOC_TYPES.map((value) => (
								<IntlMessages key={value} id={value}>
									{(text) => (
										<option value={value}>{text}</option>
									)}
								</IntlMessages>
							))}
						</select>
					</div>
				</div>
				<div className="form-group row">
					<label htmlFor="dni" className="col-sm-4 col-form-label">
						<IntlMessages id="dniNumber" />
					</label>
					<div className="col-sm-8">
						<IntlMessages id="dniNames">
							{(text) => (
								<input
									maxLength={20}
									type="text"
									disabled
									defaultValue={purchaser.identificationNo}
									className="form-control"
									id="dni"
									placeholder={text}
								/>
							)}
						</IntlMessages>
					</div>
				</div>
				<div className="form-group row">
					<label
						htmlFor="expirationDate"
						className="col-sm-4 col-form-label"
					>
						<IntlMessages id="expirationDate" />
					</label>
					<div className="col-sm-8">
						<input
							type="date"
							id="expirationDate"
							disabled
							defaultValue={purchaser.expireDate}
							className="form-control"
						/>
					</div>
				</div>
				<div className="form-group row">
					<label htmlFor="names" className="col-sm-4 col-form-label">
						<IntlMessages id="names" />
					</label>
					<div className="col-sm-4">
						<IntlMessages id="firstName">
							{(text) => (
								<input
									type="text"
									disabled
									defaultValue={`${purchaser.name} ${purchaser.lastName}`}
									className="form-control"
									maxLength={20}
									id="firstName"
									placeholder={text}
								/>
							)}
						</IntlMessages>
					</div>
				</div>
				{birthDateComponent}

				<div className="form-group row">
					<label htmlFor="sex" className="col-sm-4">
						<IntlMessages id="sex" />
					</label>
					<div className="col-sm-8">
						<select
							id="sex"
							className="form-control"
							defaultValue={purchaser.sex}
							disabled
						>
							<IntlMessages id="male">
								{(text) => <option value={'m'}>{text}</option>}
							</IntlMessages>
							<IntlMessages id="female">
								{(text) => <option value={'f'}>{text}</option>}
							</IntlMessages>
						</select>
					</div>
				</div>

				{(purchaser.type === PURCHASER_TYPE_RESIDENT ||
					purchaser.type === PURCHASER_TYPE_CITIZEN) && (
					<div className="form-group row">
						<label
							htmlFor="plasticNumber"
							className="col-sm-4 col-form-label"
						>
							<IntlMessages id="plasticNumber" />
						</label>
						<div className="col-sm-8">
							<input
								type="text"
								maxLength={45}
								disabled
								defaultValue={purchaser.plasticNo}
								className="form-control"
								id="plasticNumber"
								name="plasticNumber"
							/>
						</div>
					</div>
				)}

				{purchaser.type === PURCHASER_TYPE_TOURIST && (
					<Fragment>
						<div className="form-group row">
							<label
								htmlFor="arrivalDate"
								className="col-sm-4 col-form-label"
							>
								<IntlMessages id="arrivalDate" />
							</label>
							<div className="col-sm-8">
								<input
									type="date"
									id="arrivalDate"
									disabled
									defaultValue={purchaser.arrivalDate}
									className="form-control"
								/>
							</div>
						</div>
						<div className="form-group row">
							<label
								htmlFor="lengthOfStay"
								className="col-sm-4 col-form-label"
							>
								<IntlMessages id="lengthOfStay" />
							</label>
							<div className="col-sm-8">
								<IntlMessages id="durationInDays">
									{(text) => (
										<input
											type="number"
											max={90}
											min={1}
											disabled
											defaultValue={
												purchaser.lengthOfStay
											}
											className="form-control"
											id="lengthOfStay"
											placeholder={text}
										/>
									)}
								</IntlMessages>
							</div>
						</div>
						<div className="form-group row">
							<label
								htmlFor="countryOfBirth"
								className="col-sm-4 col-form-label"
							>
								<IntlMessages id="countryOfBirth" />
							</label>
							<div className="col-sm-8">
								<input
									type="text"
									id="countryOfBirth"
									maxLength={50}
									disabled
									defaultValue={purchaser.countryOfBirth}
									className="form-control"
								/>
							</div>
						</div>
					</Fragment>
				)}

				<div className="form-group row">
					<label
						htmlFor="createdDate"
						className="col-sm-4 col-form-label"
					>
						<IntlMessages id="createdDate" />
					</label>
					<div className="col-sm-8">
						<input
							type="date"
							id="createdDate"
							disabled
							defaultValue={purchaser.createdAt}
							className="form-control"
						/>
					</div>
				</div>
				{!!purchaser.profile && (
					<span>
						<div className="form-group row">
							<label
								htmlFor="taxFreeAmountSpent"
								className="col-sm-4 col-form-label"
							>
								<IntlMessages id="taxFreeAmountSpent" />
							</label>
							<div className="col-sm-8">
								<input
									type="text"
									id="taxFreeAmountSpent"
									disabled
									defaultValue={
										purchaser.profile.taxFreeAmountSpent
									}
									className="form-control"
								/>
							</div>
						</div>
						<div className="form-group row">
							<label
								htmlFor="familyPurchaseTransferred"
								className="col-sm-4 col-form-label"
							>
								<IntlMessages id="familyPurchaseTransferred" />
							</label>
							<div className="col-sm-8">
								<input
									type="text"
									id="familyPurchaseTransferred"
									disabled
									defaultValue={
										purchaser.profile
											.familyPurchaseTransferred
									}
									className="form-control"
								/>
							</div>
						</div>
						<div className="form-group row">
							<label
								htmlFor="purchaseAmount"
								className="col-sm-4 col-form-label"
							>
								<IntlMessages id="purchaseAmount" />
							</label>
							<div className="col-sm-8">
								<input
									type="text"
									id="purchaseAmount"
									disabled
									defaultValue={purchaser.profile.amount}
									className="form-control"
								/>
							</div>
						</div>
					</span>
				)}

				{/*<div className="form-group row">
          <label htmlFor="expirationBalance" className="col-sm-4 col-form-label"><IntlMessages
            id='expirationBalance'/></label>
          <div className="col-sm-8">
            <input type="date" id="expirationBalance" disabled defaultValue={purchaser.expirationBalance || ''}
                   className="form-control"/>
          </div>
        </div>

        <Typography variant="h6" color="textPrimary" className={classes.title} gutterBottom>
          <IntlMessages id='lastPurchase'/>
        </Typography>

        <div className="form-group row">
          <label htmlFor="lastPurchaseDate" className="col-sm-4 col-form-label"><IntlMessages
            id='lastPurchaseDate'/></label>
          <div className="col-sm-8">
            <input type="date" id="lastPurchaseDate" disabled defaultValue={purchaser.lastPurchaseDate || ''}
                   className="form-control"/>
          </div>
        </div>

        */}
				<div className="form-group row">
					<div className="col-sm d-flex justify-content-end">
						<Link to={'/'}>
							<button
								type="button"
								className="btn btn-danger align-self-center"
							>
								<IntlMessages id="close" />
							</button>
						</Link>
					</div>
				</div>
			</div>
		);
	}
}

const styles = () => ({
	title: {
		fontSize: 18,
		color: '#A5A4A4',
	},
	resume: {
		fontSize: 14,
		color: '#A5A4A4',
		fontStyle: 'italic',
		fontWeight: '100',
	},
	description: {
		fontSize: 12,
		color: '#A5A4A4',
	},
});

export default withStyles(styles)(PurchaserDetail);
