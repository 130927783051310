import React, { Component, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import IntlMessages from '../lang/IntlMessages';

import { withStyles } from '@material-ui/core';
import {
	DOC_TYPE_DNI,
	DOC_TYPES,
	ERROR_PURCHASER_ALREADY_EXIST,
	PURCHASER_TYPE_CITIZEN,
	PURCHASER_TYPE_RESIDENT,
	PURCHASER_TYPE_TOURIST,
	PURCHASER_TYPES,
} from '../constants/data';
import { save } from '../services/purchaser-service';
import { withRouter } from 'react-router';
import { NotificationManager } from 'react-notifications';

class PurchaserSignUp extends Component {
	state = {
		purchaser: {
			id: '',
			identificationType: '',
			identificationNo: '',
			name: '',
			lastName: '',
			type: '',
			plasticNo: '',
			birthDate: '',
			sex: '',
			expireDate: '',
			arrivalDate: '',
			lengthOfStay: 0,
			countryOfBirth: '',
			address: '',
			createdAt: '',
			updatedAt: '',
			createdBy: 0,
			updatedBy: 0,
		},
		loading: false,
		errors: {},
	};

	validateErrors = () => {
		let files = [
			'identificationType',
			'identificationNo',
			'identificationNo',
			'name',
			'lastName',
			'type',
			'birthDate',
			'sex',
			'expireDate',
		];

		if (
			this.state.purchaser.type === PURCHASER_TYPE_CITIZEN ||
			this.state.purchaser.type === PURCHASER_TYPE_RESIDENT
		)
			files.push('plasticNo');
		else {
			files.push('arrivalDate');
			files.push('countryOfBirth');
			files.push('lengthOfStay');
			files.push('address');
		}

		let errors = 0;
		const showErrors = {};
		let notShowMessage = false;
		for (let value of files) {
			showErrors[value] = true;
			if (!this.state.purchaser[value]) {
				showErrors[value] = true;
				errors++;
			} else {
				showErrors[value] = false;
			}
		}
		this.setState({ ...this.state, errors: showErrors });

		if (errors > 0) {
			if (!notShowMessage)
				NotificationManager.error('Todos campos son requeridos');
			return false;
		}

		if (
			this.state.purchaser['birthDate'] &&
			this.state.purchaser['expireDate']
		) {
			if (
				new Date(this.state.purchaser['expireDate']).getTime() -
					new Date(this.state.purchaser['birthDate']).getTime() <=
				0
			) {
				NotificationManager.error(
					'La fecha de expiración debe ser mayor a la fecha de nacimiento'
				);
				return false;
			}
		}

		return true;
	};

	onSave(e) {
		e.preventDefault();

		if (!this.validateErrors()) return;

		if (this.state.loading) return;
		let purchaser = {};
		for (let k in this.state.purchaser) {
			const value = this.state.purchaser[k];
			if (!!value) {
				purchaser[k] = value;
			}
		}
		this.setState({ loading: true });
		save(purchaser)
			.then(() =>
				this.props.history.push(
					`/home/purchaser/${
						this.state.purchaser.identificationNo
					}?expireDate=${this.state.purchaser.expireDate.trim()}`
				)
			)
			.catch((e) => {
				console.warn(e);
				if (
					typeof e.response !== 'undefined' &&
					e.response.data.code === ERROR_PURCHASER_ALREADY_EXIST
				)
					NotificationManager.error(
						'Comprador ya se encuentra registrado'
					);
				else if (
					typeof e.response !== 'undefined' &&
					e.response.data.code === 'EXPIRED_DOCUMENT'
				)
					NotificationManager.error('Documento expirado');
				else if (
					typeof e.response !== 'undefined' &&
					e.response.data.code === 'INVALID_DATE'
				)
					NotificationManager.error('Fecha de nacimiento invalida');
				else NotificationManager.error('Error desconocido');
			})
			.finally(() => this.setState({ loading: false }));
		return false;
	}

	onChange({ value, name }) {
		if (name) {
			if (value)
				this.setState({
					errors: { ...this.state.errors, [name]: false },
				});
			this.setState(
				{ purchaser: { ...this.state.purchaser, [name]: value } },
				() => {
					if (name === 'type') {
						if (value !== PURCHASER_TYPE_RESIDENT) {
							this.setState({
								purchaser: {
									...this.state.purchaser,
									plasticNo: '',
								},
							});
						} else {
							this.setState({
								purchaser: {
									...this.state.purchaser,
									arrivalDate: '',
									lengthOfStay: 0,
									countryOfBirth: '',
								},
							});
						}
					}
				}
			);
		}
	}

	render() {
		const { classes } = this.props;
		const { purchaser } = this.state;

		return (
			<Fragment>
				<Typography
					variant="h6"
					color="textPrimary"
					className={classes.title}
					gutterBottom
				>
					<IntlMessages id="signUp" />
				</Typography>

				<form onSubmit={(e) => this.onSave(e)}>
					<div className="form-group row">
						<label
							htmlFor="type"
							className={
								'col-sm-4 col-form-label ' +
								(this.state.errors.identificationType
									? 'text-danger'
									: '')
							}
						>
							<IntlMessages id="docType" />
							<span className="text-danger">*</span>
						</label>
						<div className="col-sm-8">
							<select
								id="identificationType"
								name="identificationType"
								onChange={(e) => this.onChange(e.target)}
								defaultValue={''}
								className={
									'form-control ' +
									(this.state.errors.identificationType
										? 'is-invalid'
										: '')
								}
							>
								<IntlMessages id="choose">
									{(text) => (
										<option value={''} disabled>
											{text}...
										</option>
									)}
								</IntlMessages>
								{DOC_TYPES.map((value) => (
									<IntlMessages key={value} id={value}>
										{(text) => (
											<option value={value}>
												{text}
											</option>
										)}
									</IntlMessages>
								))}
							</select>
						</div>
					</div>
					<div className="form-group row">
						<label
							htmlFor="identificationNo"
							className={
								'col-sm-4 col-form-label ' +
								(this.state.errors.identificationNo
									? 'text-danger'
									: '')
							}
						>
							<IntlMessages id="dniNumber" />
							<span className="text-danger">*</span>
						</label>
						<div className="col-sm-8">
							<IntlMessages id="dniNames">
								{(text) => (
									<input
										type="text"
										className={
											'form-control ' +
											(this.state.errors.identificationNo
												? 'is-invalid'
												: '')
										}
										defaultValue={
											purchaser.identificationNo
										}
										id="identificationNo"
										name="identificationNo"
										maxLength={20}
										onChange={(e) =>
											this.onChange(e.target)
										}
										placeholder={text}
									/>
								)}
							</IntlMessages>
						</div>
					</div>
					<div className="form-group row">
						<label
							htmlFor="expireDate"
							className={
								'col-sm-4 col-form-label ' +
								(this.state.errors.expireDate
									? 'text-danger'
									: '')
							}
						>
							<IntlMessages id="expirationDate" />
							<span className="text-danger">*</span>
						</label>
						<div className="col-sm-8">
							<input
								type="date"
								id="expireDate"
								name="expireDate"
								onChange={(e) => this.onChange(e.target)}
								className={
									'form-control ' +
									(this.state.errors.expireDate
										? 'is-invalid'
										: '')
								}
							/>
						</div>
					</div>
					<div className="form-group row">
						<label
							htmlFor="names"
							className={
								'col-sm-4 col-form-label ' +
								(this.state.errors.name ||
								this.state.errors.lastName
									? 'text-danger'
									: '')
							}
						>
							<IntlMessages id="names" />
							<span className="text-danger">*</span>
						</label>
						<div className="col-sm-4">
							<IntlMessages id="firstName">
								{(text) => (
									<input
										type="text"
										className={
											'form-control ' +
											(this.state.errors.name
												? 'is-invalid'
												: '')
										}
										id="name"
										name="name"
										maxLength={20}
										onChange={(e) =>
											this.onChange(e.target)
										}
										placeholder={text}
									/>
								)}
							</IntlMessages>
						</div>
						<div className="col-sm-4">
							<IntlMessages id="lastName">
								{(text) => (
									<input
										type="text"
										maxLength={20}
										className={
											'form-control ' +
											(this.state.errors.lastName
												? 'is-invalid'
												: '')
										}
										id="lastName"
										name="lastName"
										onChange={(e) =>
											this.onChange(e.target)
										}
										placeholder={text}
									/>
								)}
							</IntlMessages>
						</div>
					</div>
					<div className="form-group row">
						<label
							htmlFor="birthDate"
							className={
								'col-sm-4 col-form-label ' +
								(this.state.errors.birthDate
									? 'text-danger'
									: '')
							}
						>
							<IntlMessages id="birthdate" />
							<span className="text-danger">*</span>
						</label>
						<div className="col-sm-8">
							<input
								type="date"
								id="birthDate"
								name="birthDate"
								onChange={(e) => this.onChange(e.target)}
								className={
									'form-control ' +
									(this.state.errors.birthDate
										? 'is-invalid'
										: '')
								}
							/>
						</div>
					</div>

					<div className="form-group row">
						<label
							htmlFor="sex"
							className={
								'col-sm-4 col-form-label ' +
								(this.state.errors.sex ? 'text-danger' : '')
							}
						>
							<IntlMessages id="sex" />
							<span className="text-danger">*</span>
						</label>
						<div className="col-sm-8">
							<select
								id="sex"
								name="sex"
								onChange={(e) => this.onChange(e.target)}
								className={
									'form-control ' +
									(this.state.errors.sex ? 'is-invalid' : '')
								}
								defaultValue={''}
							>
								<IntlMessages id="choose">
									{(text) => (
										<option value={''} disabled>
											{text}...
										</option>
									)}
								</IntlMessages>
								<IntlMessages id="male">
									{(text) => (
										<option value={'m'}>{text}</option>
									)}
								</IntlMessages>
								<IntlMessages id="female">
									{(text) => (
										<option value={'f'}>{text}</option>
									)}
								</IntlMessages>
							</select>
						</div>
					</div>

					<div className="form-group row">
						<label
							htmlFor="type"
							className={
								'col-sm-4 col-form-label ' +
								(this.state.errors.type ? 'is-invalid' : '')
							}
						>
							<IntlMessages id="purchaserType" />
							<span className="text-danger">*</span>
						</label>
						<div className="col-sm-8">
							<select
								id="type"
								name="type"
								onChange={(e) => this.onChange(e.target)}
								defaultValue={''}
								className={
									'form-control ' +
									(this.state.errors.type ? 'is-invalid' : '')
								}
							>
								<IntlMessages id="choose">
									{(text) => (
										<option value={''} disabled>
											{text}...
										</option>
									)}
								</IntlMessages>
								{PURCHASER_TYPES.map((value) => {
									if (
										this.state.purchaser
											.identificationType ===
											DOC_TYPE_DNI &&
										value === PURCHASER_TYPE_TOURIST
									)
										return null;

									return (
										<IntlMessages key={value} id={value}>
											{(text) => (
												<option value={value}>
													{text}
												</option>
											)}
										</IntlMessages>
									);
								})}
							</select>
						</div>
					</div>

					{(purchaser.type === PURCHASER_TYPE_RESIDENT ||
						purchaser.type === PURCHASER_TYPE_CITIZEN) && (
						<div className="form-group row">
							<label
								htmlFor="plasticNo"
								className={
									'col-sm-4 col-form-label ' +
									(this.state.errors.plasticNo
										? 'text-danger'
										: '')
								}
							>
								<IntlMessages id="plasticNumber" />
								<span className="text-danger">*</span>
							</label>
							<div className="col-sm-8">
								<input
									type="text"
									maxLength={45}
									onChange={(e) => this.onChange(e.target)}
									className={
										'form-control ' +
										(this.state.errors.plasticNo
											? 'is-invalid'
											: '')
									}
									id="plasticNo"
									name="plasticNo"
								/>
							</div>
						</div>
					)}

					{purchaser.type === PURCHASER_TYPE_TOURIST && (
						<Fragment>
							<div className="form-group row">
								<label
									htmlFor="arrivalDate"
									className={
										'col-sm-4 col-form-label ' +
										(this.state.errors.arrivalDate
											? 'text-danger'
											: '')
									}
								>
									<IntlMessages id="arrivalDate" />
									<span className="text-danger">*</span>
								</label>
								<div className="col-sm-8">
									<input
										type="date"
										id="arrivalDate"
										name="arrivalDate"
										onChange={(e) =>
											this.onChange(e.target)
										}
										className={
											'form-control ' +
											(this.state.errors.arrivalDate
												? 'is-invalid'
												: '')
										}
									/>
								</div>
							</div>
							<div className="form-group row">
								<label
									htmlFor="lengthOfStay"
									className={
										'col-sm-4 col-form-label ' +
										(this.state.errors.lengthOfStay
											? 'text-danger'
											: '')
									}
								>
									<IntlMessages id="lengthOfStay" />
									<span className="text-danger">*</span>
								</label>
								<div className="col-sm-8">
									<IntlMessages id="durationInDays">
										{(text) => (
											<input
												type="number"
												max={90}
												min={1}
												className={
													'form-control ' +
													(this.state.errors
														.lengthOfStay
														? 'is-invalid'
														: '')
												}
												id="lengthOfStay"
												name="lengthOfStay"
												onChange={(e) =>
													this.onChange(e.target)
												}
												placeholder={text}
											/>
										)}
									</IntlMessages>
								</div>
							</div>
							<div className="form-group row">
								<label
									htmlFor="countryOfBirth"
									className={
										'col-sm-4 col-form-label ' +
										(this.state.errors.countryOfBirth
											? 'text-danger'
											: '')
									}
								>
									<span className="text-danger">*</span>
									<IntlMessages id="countryOfBirth" />
								</label>
								<div className="col-sm-8">
									<input
										type="text"
										id="countryOfBirth"
                                        maxLength={50}
										name="countryOfBirth"
										onChange={(e) =>
											this.onChange(e.target)
										}
										className={
											'form-control ' +
											(this.state.errors.countryOfBirth
												? 'is-invalid'
												: '')
										}
									/>
								</div>
							</div>
							<div className="form-group row">
								<label
									htmlFor="address"
									className={
										'col-sm-4 col-form-label ' +
										(this.state.errors.address
											? 'text-danger'
											: '')
									}
								>
									<span className="text-danger">*</span>
									<IntlMessages id="address" />
								</label>
								<div className="col-sm-8">
									<textarea
										id="address"
                                        maxLength={50}
										name="address"
										onChange={(e) =>
											this.onChange(e.target)
										}
										rows={4}
										className={
											'form-control ' +
											(this.state.errors.address
												? 'is-invalid'
												: '')
										}
									/>
								</div>
							</div>
						</Fragment>
					)}

					<div className="form-group row">
						<div className="col-sm d-flex justify-content-end">
							<button
								disabled={this.state.loading}
								type="submit"
								className="btn btn-primary align-self-center"
							>
								<IntlMessages id="signUp" />
							</button>
						</div>
					</div>
				</form>
			</Fragment>
		);
	}
}

const styles = () => ({
	title: {
		fontSize: 18,
		color: '#A5A4A4',
	},
	body: {
		fontSize: 12,
		color: '#A5A4A4',
	},
});

export default withRouter(withStyles(styles)(PurchaserSignUp));
