/* eslint-disable react/prop-types */
import React from 'react';
import { useCallback, useState, useRef, useContext } from 'react';
import { GoogleMap, useJsApiLoader, Polygon } from '@react-google-maps/api';

import { ExpandableRowContext } from './DataTale.context';
import { getPropertyTitle } from '../App.hooks'
import {
	ZOOM,
	GOOGLE_MAPS_OPTIONS,
	GOOGLE_MAP_CONTAINER_STYLE,
	GOOGLE_MAP_POLYGON_OPTIONS,
	CENTER,
} from '../App.constants';

export const ExpandableRow = ({ data }) => {
	const [path, setPath] = useState(data?.path ? data.path : null);

	return (
		<ExpandableRowContext.Provider
			value={{
				plot: data,
				path,
				setPath,
			}}
		>
			<section className="expandable-row">
				<DataComponent />
				<div>
					<div>
						<MapComponent />
					</div>
				</div>
			</section>
		</ExpandableRowContext.Provider>
	);
};

function MapComponent() {
	// Define refs for Polygon instance and listeners
	const { path } = useContext(ExpandableRowContext);

	const polygonRef = useRef(null);
	const listenersRef = useRef([]);

	const { isLoaded, loadError } = useJsApiLoader(GOOGLE_MAPS_OPTIONS);

	const polygonOptions = {
		...GOOGLE_MAP_POLYGON_OPTIONS,
		draggable: false,
		editable: false,
	};

	// Clean up refs
	const onUnmount = useCallback(() => {
		listenersRef.current.forEach((lis) => lis.remove());
		polygonRef.current = null;
	}, []);

	if (loadError) {
		return (
			<div className={'map-container error'}>
				Error loading the map please refresh the page
			</div>
		);
	}

	if (!path) {
		return <PlotNotDefined />;
	}

	return isLoaded ? (
		<div className="map-container" style={{ position: 'relative' }}>
			<GoogleMap
				zoom={ZOOM}
				center={path ? findCenter(path) : CENTER}
				mapContainerStyle={GOOGLE_MAP_CONTAINER_STYLE}
			>
				{path ? (
					<Polygon
						path={path}
						options={polygonOptions}
						onUnmount={onUnmount}
					/>
				) : null}
			</GoogleMap>
		</div>
	) : null;
}

function PlotNotDefined() {
	return (
		<div className="map-container" style={{ position: 'relative' }}>
			<div className="not-found">Plot Not found</div>
		</div>
	);
}

function DataComponent() {
	const { plot } = useContext(ExpandableRowContext);
	const {
		id,
		plan,
		block,
		street,
		avenue,
		estate,
		property,
		lot,
		type,
		owner,
		condition,
		category,
		area,
		range,
		certification,
		areaCode,
		corrections,
		lang
	} = plot;

	return (
		<div className="data-container" data-plot-id={id}>
			<form>
				<span>
					<h2>{lang === 'en' ? 'Information' : 'Información'}</h2>
				</span>
				<div>
					<h3>{getPropertyTitle('plan', lang)}</h3>
					<p>{plan}</p>
				</div>
				<div>
					<h3>{getPropertyTitle('block', lang)}</h3>
					<p>{block}</p>
				</div>
				<div>
					<h3>{getPropertyTitle('street', lang)}</h3>
					<p>{street}</p>
				</div>
				<div>
					<h3>{getPropertyTitle('avenue', lang)}</h3>
					<p>{avenue}</p>
				</div>
				<div>
					<h3>{getPropertyTitle('corrections', lang)}</h3>
					<p>{corrections}</p>
				</div>
				{estate ? (
					<div>
						<h3>{getPropertyTitle('estate', lang)}</h3>
						<p>{estate}</p>
					</div>
				) : null}
				{property ? (
					<div>
						<h3>{getPropertyTitle('property', lang)}</h3>
						<p>{property}</p>
					</div>
				) : null}
				<div>
					<h3>{getPropertyTitle('lot', lang)}</h3>
					<p>{lot}</p>
				</div>
				<div>
					<h3>{getPropertyTitle('type', lang)}</h3>
					<p>{type}</p>
				</div>
				<div>
					<h3>{getPropertyTitle('owner', lang)}</h3>
					<p>{owner}</p>
				</div>
				<div>
					<h3>{getPropertyTitle('condition', lang)}</h3>
					<p>{condition}</p>
				</div>
				<div>
					<h3>{getPropertyTitle('category', lang)}</h3>
					<p>{category}</p>
				</div>
				{area ? (
					<div>
						<h3>{getPropertyTitle('area', lang)}</h3>
						<p>{area}</p>
					</div>
				) : null}

				<div>
					<h3>{getPropertyTitle('range', lang)}</h3>
					<p>{range}</p>
				</div>
				<div>
					<h3>{getPropertyTitle('certification', lang)}</h3>
					<p>{certification ? (lang === 'es' ? 'Si' : 'Yes') : 'No'}</p>
				</div>
				{areaCode ? (
					<div>
						<h3>{getPropertyTitle('areaCode', lang)}</h3>
						<p>{areaCode}</p>
					</div>
				) : null}
				<div className="actions"></div>
			</form>
		</div>
	);
}

function findCenter(markers) {
	const lats = markers.map((m) => m.lat);
	const lngs = markers.map((m) => m.lng);
	return {
		lat: (Math.min(...lats) + Math.max(...lats)) / 2,
		lng: (Math.min(...lngs) + Math.max(...lngs)) / 2,
	};
}
