import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Typography from "@material-ui/core/Typography";
import classnames from 'classnames';

import IntMessage from "../lang/IntlMessages";
import HomeAnnouncement from '../components/home-announcement';
import HomeNewsComponent from '../components/home-news';
import {Link} from "react-router-dom";

const styles = () => ({
    header: {
        width: 'auto',
        height: 250,
        backgroundImage: `url(${require('../assets/bg/header_home.png')})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    title: {
        fontSize: 18,
        color: '#A5A4A4'
    },
    body: {
        fontSize: 12,
        color: '#A5A4A4'
    }
});

const homeBody = ({classes, announcements, newsList, events}) => (
    <div className="container pb-sm-3 pb-lg-4">
        <div className="row">
            <div className="col-md-7">
                <section className="pt-4 pt-lg-3">
                    <Typography variant="h6" color="textPrimary" className={classes.title} gutterBottom>
                        <IntMessage id="what_we_do"/>
                    </Typography>
                    <Typography component="p" className={classnames(classes.body, 'text-justify')}>
                        <IntMessage id="what_we_do_content"/>
                    </Typography>
                </section>
                <br/>
                <section className="pt-4 pt-lg-3">
                    <Typography variant="h6" color="textPrimary" className={classes.title} gutterBottom>
                        <IntMessage id="what_can_you_buy"/>
                    </Typography>
                    <Typography component="p" className={classnames(classes.body, 'text-justify')}>
                        <IntMessage id="what_can_you_buy_content"/>
                    </Typography>
                </section>
                <br/>
                <section className="pt-4 pt-lg-3">
                    <Typography variant="h6" color="textPrimary" className={classes.title} gutterBottom>
                        <IntMessage id="requirements_title"/>
                    </Typography>
                    <Typography variant="h3" color="textPrimary" className={classes.title} gutterBottom>
                        <IntMessage id="requirements_trading_title"/>
                    </Typography>
                    <Typography component="div" className={classnames(classes.body, 'text-justify')}>
                        <ol>
                            <li>
                            <Typography component="p" className={classnames(classes.body, 'text-justify')}>
                                <IntMessage id="requirements_trading_content_1"/>    
                            </Typography>
                                
                            </li>
                            <li>
                                <IntMessage id="requirements_trading_content_2"/>
                            </li>
                            <li>
                                <IntMessage id="requirements_trading_content_3"/>
                            </li>
                            <li>
                                <IntMessage id="requirements_trading_content_4"/>
                            </li>
                            <li>
                                <IntMessage id="requirements_trading_content_5"/>
                            </li>
                            <li>
                                <IntMessage id="requirements_trading_content_6"/>
                            </li>
                            <li>
                                <IntMessage id="requirements_trading_content_7"/>
                            </li>
                        </ol>
                    </Typography>
                    <Typography variant="h3" color="textPrimary" className={classes.title} gutterBottom>
                        <IntMessage id="requirements_investors_title"/>
                    </Typography>
                    <Typography component="div" className={classnames(classes.body, 'text-justify')}>
                        <ol>
                            <li>
                                <IntMessage id="requirements_investors_content_1"/>
                            </li>
                            <li>
                                <IntMessage id="requirements_investors_content_2"/>
                            </li>
                            <li>
                                <IntMessage id="requirements_investors_content_3"/>
                            </li>
                            <li>
                                <IntMessage id="requirements_investors_content_4"/>
                            </li>
                            <li>
                                <IntMessage id="requirements_investors_content_5"/>
                            </li>
                        </ol>
                    </Typography>
                </section>
                <section className="pt-4 pt-lg-3">
                    <Typography variant="h6" color="textPrimary" className={classes.title} gutterBottom>
                        <IntMessage id="incentives_title"/>
                    </Typography>
                    <Typography variant="h3" color="textPrimary" className={classes.title} gutterBottom>
                        <IntMessage id="incentives_investors_title"/>
                    </Typography>
                    <Typography component="div" className={classnames(classes.body, 'text-justify')}>
                        <ul>
                            <li>
                                <IntMessage id="incentives_investors_content_1"/>
                            </li>
                            <li>
                                <IntMessage id="incentives_investors_content_2"/>
                            </li>
                            <li>
                                <IntMessage id="incentives_investors_content_3"/>
                            </li>
                            <li>
                                <IntMessage id="incentives_investors_content_4"/>
                            </li>
                            <li>
                                <IntMessage id="incentives_investors_content_5"/>
                            </li>
                        </ul>
                    </Typography>
                    <Typography variant="h3" color="textPrimary" className={classes.title} gutterBottom>
                        <IntMessage id="incentives_merchants_title"/>
                    </Typography>
                    <Typography component="div" className={classnames(classes.body, 'text-justify')}>
                        <ul>
                            <li>
                                <IntMessage id="incentives_merchants_content_1"/>
                            </li>
                            <li>
                                <IntMessage id="incentives_merchants_content_2"/>
                            </li>
                            <li>
                                <IntMessage id="incentives_merchants_content_3"/>
                            </li>
                        </ul>
                    </Typography>
                </section>
                <br/>
                <section className="pt-4 pt-lg-3">
                    <Typography variant="h6" color="textPrimary" className={classes.title} gutterBottom>
                        <IntMessage id="benefits_title"/>
                    </Typography>
                    <Typography variant="h3" color="textPrimary" className={classes.title} gutterBottom>
                        <IntMessage id="benefits_consumers_title"/>
                    </Typography>
                    <Typography component="div" className={classnames(classes.body, 'text-justify')}>
                        <ul>
                            <li><IntMessage id="benefits_consumers_content"/></li>
                        </ul>
                    </Typography>
                    <Typography variant="h3" color="textPrimary" className={classes.title} gutterBottom>
                        <IntMessage id="benefits_panamenians_title"/>
                    </Typography>
                    <Typography component="div" className={classnames(classes.body, 'text-justify')}>
                        <ul>
                            <li><IntMessage id="benefits_panamenians_content"/></li>
                        </ul>
                    </Typography>
                </section>
                <section className="pt-4 pt-lg-3">
                    <div className="section-title d-flex justify-content-between">
                        <Typography variant="h6" color="textPrimary" className={classes.title} gutterBottom>
                            <IntMessage id="announcements"/>
                        </Typography>
                        <Link to={'/list/announcements'}>
                            <ArrowForwardIcon/>
                        </Link>

                    </div>
                    <div className="announcements-container row">
                        {(announcements || []).map(announcement => (
                            <div className="col-sm-6 pb-2" key={announcement.id}>
                                <HomeAnnouncement {...announcement}/>
                            </div>
                        ))}
                    </div>
                </section>
            </div>
            <div className="col-md">
                <section className="pt-4 pt-lg-3">
                    <div className="section-title d-flex justify-content-between">
                        <Typography variant="h6" color="textPrimary" className={classes.title} gutterBottom>
                            <IntMessage id="news"/>
                        </Typography>
                        <Link to={'/list/news'}>
                            <ArrowForwardIcon/>
                        </Link>
                    </div>
                    <div className="events-container row">
                        {(newsList || []).map(news => (
                            <div className="col-sm pb-2" key={news.id}>
                                <HomeNewsComponent {...{
                                    ...news,
                                    description: news.resume,
                                    link: news.link !== '#' ? news.link : `/news/${news.id}`
                                }}/>
                            </div>
                        ))}
                    </div>
                </section>
                <section className="pt-4 pt-lg-3">
                    <div className="section-title d-flex justify-content-between">
                        <Typography variant="h6" color="textPrimary" className={classes.title} gutterBottom>
                            <IntMessage id="events"/>
                        </Typography>
                        <Link to={'/list/events'}>
                            <ArrowForwardIcon/>
                        </Link>
                    </div>
                    <div className="events-container row">
                        {(events || []).map(event => (
                            <div className="col-sm pb-2" key={event.id}>
                                <HomeNewsComponent {...event}/>
                            </div>
                        ))}
                    </div>
                </section>
            </div>
        </div>

    </div>

);

export default withStyles(styles)(homeBody);
