import React, {Component} from 'react';
import './App.css';
import {Provider} from 'react-redux';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {loadReCaptcha} from 'react-recaptcha-google'

import MainApp from './container/Main';
import {configureStore} from "./store";

import 'bootstrap/dist/css/bootstrap.css';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from "react-notifications";

class App extends Component {

    componentDidMount() {

        loadReCaptcha();
    }

    render() {
        return (
            <Provider store={configureStore({})}>
                <Router>
                    <Switch>
                        <Route path="/" render={props => <MainApp {...props}/>}/>
                    </Switch>
                </Router>
                <NotificationContainer/>
            </Provider>
        )
    }
}

export default App;
