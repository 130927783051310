import { createContext } from 'react';

export const DataTableContext = createContext({
	filter: {},
	setFilter: () => {},
	plots: [],
});

export const ExpandableRowContext = createContext({
	path: [],
	setPath: () => {},
	plot: null,
});
