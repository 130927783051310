import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import './Infrastructure.css';
import { DataTableComponent } from './DataTable';

import { onSectionLoad } from '../Events';

const styles = (theme) => ({
	title: {
		fontSize: 18,
		color: '#A5A4A4',
		marginTop: '5%',
		marginBottom: 50,
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: '33.33%',
		flexShrink: 0,
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
	},
	headerContainer: {
		flex: 1,
		flexDirection: 'row',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
});

function Infrastructure(props) {
	useEffect(() => {
		onSectionLoad('infrastructure')
	}, [])

	const { locale } = props;
	return (
		<div className="infrastructure-container">
			<DataTableComponent lang={locale.locale} />
		</div>
	);
}

function mapStateToProps({ settings: { locale } }) {
	return {
		locale,
	};
}

export default connect(mapStateToProps)(withStyles(styles)(Infrastructure));
