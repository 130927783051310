export const events = [
    {
        id: 1,
        title: 'Ministro Recorre Proyecto privado con incentivos de Colón Puerto Libre',
        description: 'El Ministro <a href="https://twitter.com/A_AROSEMENA_M" target="_blank">@A_AROSEMENA_M</a> recorrió el proyecto <a href="https://twitter.com/Colon2000dfree" target="_blank">@Colon2000dfree</a> que registra un porcentaje de avance del 30%.  Se trata del primer proyecto privado de gran magnitud que funcionará con los incentivo de <a href="https://twitter.com/hashtag/Col%C3%B3nPuertoLibre?src=hash" target="_blank">#ColónPuertoLibre</a> <a href="https://bit.ly/2JKmscC" target="_blank">https://bit.ly/2JKmscC</a>',
        date: '2019-02-26',
        photoUrl: 'image4.jpg',
        link: ''
    }, {
        id: 2,
        title: 'Apertura de Secretaría de Colón Puerto Libre',
        description: 'La Secretaría de Colón Puerto Libre, adscrita al <b>Ministerio de Comercio e Industrias</b> (Mici) abrió sus oficinas en la ciudad de Colón, frente al parque La Concha, desde donde atenderá a inversionistas comerciales y de infraestructura interesados en aplicar este régimen especial. ',
        date: '25-02-2019',
        photoUrl: 'image12.jpg',
        link: ''
    }
];
export const newsList = [
    {
        id: '1',
        title: 'Varela sanciona la nueva Ley de Colón Puerto Libre',
        resume: 'El presidente de la República, Juan Carlos Varela sancionó este lunes 5 de noviembre la nueva Ley Colón Puerto Libre, como parte de las festividades patrias en esta ciudad de la Costa Atlántica.',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec iaculis pretium turpis, ut consequat lacus ultricies a. Vestibulum congue pulvinar lacus, at accumsan massa interdum eget. Cras fringilla vel odio ut laoreet. Integer interdum lorem quis nisl vehicula ullamcorper. Praesent aliquam ipsum id sem rutrum tristique. Morbi a diam blandit ligula consequat imperdiet ac at ligula. Duis at tellus vel odio sagittis gravida. Maecenas sollicitudin metus velit, in malesuada velit dapibus id. Aliquam erat volutpat. Donec at tellus vitae quam auctor lacinia id in arcu.\n' +
            'Phasellus arcu felis, placerat tincidunt massa sed, suscipit ullamcorper elit. Aenean a viverra eros, ac ultrices urna. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vel sapien urna. Nullam a ornare diam, in volutpat mi. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer molestie arcu quis augue tempus, nec aliquam neque fermentum.\n' +
            'Cras a mauris hendrerit, pretium neque non, dignissim augue. Nullam in ex non urna aliquet rhoncus. Mauris libero nulla, laoreet non varius sed, laoreet at ex. Nulla risus arcu, ultrices sed risus nec, tincidunt tincidunt ipsum. Nunc et turpis vel mi efficitur ultricies. Vivamus viverra commodo magna sit amet pharetra. Donec accumsan fringilla libero a tempus. Nam eget malesuada justo, a egestas neque. Duis tincidunt odio ornare dapibus commodo. Fusce pulvinar ligula quis blandit volutpat. Nulla facilisi. Vestibulum laoreet, urna sed mollis ultricies, mauris magna venenatis justo, non dignissim diam ante at mi. Donec in purus accumsan, hendrerit velit vitae, finibus velit. Nunc felis tellus, lacinia sit amet felis eu, viverra fermentum eros. Etiam nec neque lectus. Suspendisse eleifend risus vitae risus mattis volutpat.\n' +
            'Mauris porta, urna vel porta rhoncus, risus felis vehicula nibh, ut iaculis nulla nibh vel massa. Proin vehicula nec mauris id elementum. Pellentesque dictum ligula semper lorem mattis commodo. Sed metus magna, scelerisque eu volutpat sed, tempus eu augue. Morbi feugiat justo ex, eget tempus nulla venenatis sed. In eu fringilla sem. Integer eget lobortis neque, id tincidunt orci. Sed vulputate, nibh varius rhoncus maximus, elit odio faucibus nisi, sit amet porta ligula velit nec nunc. Donec dictum ligula vitae dictum feugiat. Nullam cursus vulputate risus, sit amet dictum nunc consectetur a. Duis bibendum lorem sit amet sem scelerisque molestie. Cras suscipit lorem consectetur nisi commodo, non maximus nisl blandit. Sed est neque, ultricies ut neque non, sollicitudin varius urna. Nulla sed congue ex, non ultricies nunc. Pellentesque nec sollicitudin arcu. Donec eget euismod est.\n' +
            'Donec sed nisl libero. Mauris ut augue et sapien eleifend sollicitudin. Quisque posuere et metus id hendrerit. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nunc ut quam non turpis hendrerit interdum quis ut orci. Interdum et malesuada fames ac ante ipsum primis in faucibus. Suspendisse potenti. Curabitur condimentum nunc libero, facilisis vestibulum nunc ornare sit amet. Sed metus quam, porttitor in ultricies varius, malesuada at felis. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. ',
        date: '2019-02-26',
        photoUrl: 'image13.jpg',
        link: 'https://www.tvn-2.com/economia/economia-panamena/Varela-Ley-Colon-Puerto-Libre_0_5161733790.html'
    }, {
        id: '2',
        title: 'Colón Puerto Libre será libre de ITBMS para nacionales y residentes en Panamá',
        resume: 'El proyecto contempla la exoneración para nacionales y extranjeros del Impuesto Selectivo al Consumo que se le cobra a los artículos de lujo',
        description: '',
        date: '12/09/2018',
        photoUrl: 'noticia3.jpg',
        link: 'http://laestrella.com.pa/economia/colon-puerto-libre-sera-libre-itbms-para-nacionales-residentes-panama/24082789'
    }, {
        id: '3',
        title: 'Colón Puerto Libre potenciará actividad económica y comercial en la provincia',
        resume: 'El presidente de la República, Juan Carlos Varela, sancionó este lunes la ley que modifica y optimiza los beneficios de la Ley 29 de 1992 que creó el Sistema Especial de Puerto Libre para la provincia de Colón (SEPLC)',
        description: '',
        date: '07/11/2018',
        photoUrl: 'image11.jpg',
        link: 'http://laestrella.com.pa/economia/colon-puerto-libre-sera-libre-itbms-para-nacionales-residentes-panama/24082789'
    }, {
        id: '4',
        title: 'Colón Puerto Libre estará excento de ITBMS para nacionales y residentes en Panamá',
        resume: 'El proyecto también establece el otorgamiento de los créditos fiscales como incentivo para fortalecer la inversión comercial en las dieciséis calles de la ciudad de Colón (incluyendo Colón 2,000), fomentando el efecto en el resto de la provincia.',
        description: '',
        date: '07/11/2018',
        photoUrl: 'zona_libre_de_colon-colon_puerto_libre-impuesto.jpg',
        link: 'http://laestrella.com.pa/economia/colon-puerto-libre-sera-libre-itbms-para-nacionales-residentes-panama/24082789'
    }
];
export const announcements = [
    {
        id: 1,
        title: '',
        description: 'La ley 29 del 30 de diciembre de 1992 reformada por la ley 7 de 4 de abril del 2016 materializa un anhelo del pueblo colonense que data de 1973, Colón puerto libre.',
        date: '2019-02-26',
        link: ''
    }, {
        id: 2,
        title: '',
        description: 'A través  de los  decretos 86 del 04 de agosto de 2016 se designan los integrantes de la Junta Asesora del Sistema Económico Especial de Puerto Libre para la provincia de Colón inicialmente en la 16 Calles y Avenidas, y 87 se crea la Secretaria del Sistema Especial de puerto Libre.',
        date: '2019-02-26',
        link: ''
    }, {
        id: 3,
        title: '',
        description: 'La implementación de ley Colón Puerto Libre y su reforma están en proceso de  ser implementadas por el presidente Juan Carlos Varela y su gobierno.',
        date: '2019-02-26',
        link: ''
    }, {
        id: 4,
        title: '',
        description: 'Ambas leyes de Colón Puerto Libre exceptúa de aranceles o impuestos de importación a todas las mercancías que se introduzcan al territorio del Sistema Especial de Puerto Libre.',
        date: '2019-02-26',
        link: ''
    }, {
        id: 5,
        title: '',
        description: 'Las Leyes  de Colón Puerto Libre y aunado a la existencia a menos de un metro de distancia de la Zona Libre de Colón, abre un destino importante para el turismo no solo de esta provincia sino de todo el país.',
        date: '2019-02-26',
        link: ''
    }, {
        id: 6,
        title: '',
        description: 'Leyes de Colón puerto Libre fueron ampliamente consultada por todos los sectores empresariales, Gobierno y organizaciones cívicas, específicamente del área de Colón.',
        date: '2019-02-26',
        link: ''
    }, {
        id: 7,
        title: '',
        description: 'Las leyes de Puerto Libre se implementan en momento que el Gobierno realiza una serie de inversiones en materia de infraestructura y de vivienda, lo que permite la oportunidad de poder darle la sostenibilidad a ese proceso de restauración y de desarrollo en la ciudad de Colón.',
        date: '2019-02-26',
        link: ''
    }, {
        id: 8,
        title: '',
        description: 'Entre los beneficios de las leyes Colón Puerto libre, está la generación de empleos , para mano de obra colonense.',
        date: '2019-02-26',
        link: ''
    }, {
        id: 9,
        title: '',
        description: 'Por medio de estas leyes se obtendrán nuevas inversiones en infraestructura, construcción, restauración y edificación nuevas a través de incentivos.',
        date: '2019-02-26',
        link: ''
    }, {
        id: 10,
        title: '',
        description: 'El rescate de la cultura y tradiciones de Colón, permitirá un mayor movimiento comercial en la provincia.',
        date: '2019-02-26',
        link: ''
    }, {
        id: 11,
        title: '',
        description: 'Las leyes de Puerto Libre dinamizarán económicamente a las 16 Calles y Avenidas de la ciudad de Colón, permitirá la movilidad de comercio, que todos los panameños tengan la posibilidad de hacer compras en Colón Puerto libre de hasta mil balboas semestrales, libre de impuesto.',
        date: '2019-02-26',
        link: ''
    }, {
        id: 12,
        title: '',
        description: 'Los turistas extranjeros no tienen límite de compra y estarán libre de pagos de impuestos de importación e ITBMS.',
        date: '2019-02-26',
        link: ''
    }
];

export const ERROR_PURCHASER_NOT_FOUND = 'PURCHASER_NOT_FOUND';
export const ERROR_PURCHASER_ALREADY_EXIST = 'PURCHASER_ALREADY_EXIST';


export const PURCHASER_TYPE_TOURIST = 'Tourist';
export const PURCHASER_TYPE_RESIDENT = 'Resident';
export const PURCHASER_TYPE_CITIZEN = 'Citizen';
export const PURCHASER_TYPES = [PURCHASER_TYPE_RESIDENT, PURCHASER_TYPE_TOURIST, PURCHASER_TYPE_CITIZEN];

export const DOC_TYPE_DNI = 'Cedula';
export const DOC_TYPE_PASSPORT = 'Passport';
export const DOC_TYPES = [DOC_TYPE_PASSPORT, DOC_TYPE_DNI];


export const CONTACT_TYPE_FEEDBACK = 'feedback';
export const CONTACT_TYPE_CONGRATULATIONS = 'congratulations';
export const CONTACT_TYPES = [CONTACT_TYPE_FEEDBACK, CONTACT_TYPE_CONGRATULATIONS];

