import React from 'react';
import Banner from './Banner';

export default () => {
	return (
		<div>
			<Banner />
			<AdvisoryBoardES />
		</div>
	);
};

const AdvisoryBoardES = () => {
	return (
		<div>
			<h1>JUNTA ASESORA</h1>
			<p>
				La Junta Asesora es el máximo organismo consultivo y asesor del
				Órgano Ejecutivo en las políticas del Sistema Especial de Puerto
				Libre para la provincia de Colón, la cual estará integrada por:
			</p>

			<table>
				<thead>
					<tr>
						<th>Nombre Principal</th>
						<th>Miembros</th>
						<th>Función</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>S.E. Federico Alfaro Boyd</td>
						<td>Ministro de Comercio e Industrias</td>
						<td>Presidente</td>
					</tr>
					<tr>
						<td>S.E. Héctor Alexander</td>
						<td>Ministro de Economía y Finanzas</td>
						<td>Asesor</td>
					</tr>
					<tr>
						<td>S.E. Doris Zapata Acevedo</td>
						<td>Ministro de Trabajo y Desarrollo Laboral</td>
						<td>Asesora</td>
					</tr>
					<tr>
						<td>S.E. Rogelio Paredes</td>
						<td>
							Ministerio de Vivienda y Ordenamiento territorial
						</td>
						<td>Asesor</td>
					</tr>
					<tr>
						<td>Tayra Barsallo Z.</td>
						<td>Director de la Autoridad Nacional de Aduanas</td>
						<td>Asesora</td>
					</tr>
					<tr>
						<td>H. Alex Lee</td>
						<td>Alcalde del Distrito de Colón</td>
						<td>Asesor</td>
					</tr>
					<tr>
						<td>Lic. José De La Rosa Lam</td>
						<td>
							Cámara de Comercio, Industrias y Agricultura de
							Colón
						</td>
						<td>Asesor</td>
					</tr>
					<tr>
						<td>Licda. Maira Pang</td>
						<td>Micro, Pequeña y Mediana Empresa de Colón</td>
						<td>Asesora</td>
					</tr>
					<tr>
						<td>Lic. Eugenio Meneses</td>
						<td>Representante de los trabajadores (sindicatos colonenses)</td>
						<td>Asesor</td>
					</tr>
					<tr>
						<td>
							Giovanni Ferrari <br />
							<small>Con derecho a voz únicamente</small>
						</td>
						<td>Gerente de la Zona Libre de Colón</td>
						<td>Secretario Técnico</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};
