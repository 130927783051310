import React, {Component} from 'react';
// import Recaptcha from '../components/recaptcha-google';
import Typography from "@material-ui/core/Typography";
import {withStyles} from "@material-ui/core";
import IntlMessages from '../lang/IntlMessages';
import PurchaserSignUp from "./PurchaserSignUp";
import {NotificationManager} from "react-notifications";
import {findPurchaserById} from "../services/purchaser-service";
import {ERROR_PURCHASER_NOT_FOUND} from "../constants/data";


const styles = () => ({
    title: {
        fontSize: 18,
        color: '#A5A4A4'
    },
    body: {
        fontSize: 12,
        color: '#A5A4A4'
    }
});

class PurchaserLayout extends Component {

    state = {
        dni: '',
        expireDate: '',
        loading: false,
        errors: {},
    };

    onChange({value, name}) {
        if (name) {
            this.setState({...this.state, [name]: value});
        }
    }

    validateErrors = () => {
        let files = ['dni', 'expireDate'];


        let errors = 0;
        const showErrors = {};
        let notShowMessage = false;
        for (let value of files) {
            showErrors[value] = true;
            if (!this.state[value]) {
                showErrors[value] = true;
                errors++;
            } else {
                showErrors[value] = false;
            }
        }
        this.setState({...this.state, errors: showErrors});

        if (errors > 0) {
            if (!notShowMessage)
                NotificationManager.error('Todos los campos son requeridos');
            return false;
        }
        return true;
    };

    onSearch = (e) => {
        e.preventDefault();

        if (!this.validateErrors()) return;

        if (this.state.dni.trim() === '') return;
        if (this.state.expireDate.trim() === '') return;
        this.setState({loading: true});
        findPurchaserById(this.state.dni, this.state.expireDate).then(() => {
            this.props.history.push(`/home/purchaser/${this.state.dni.trim()}?expireDate=${this.state.expireDate.trim()}`);
        }).catch(e => {
            if (typeof e.response !== 'undefined' && e.response.data.code === ERROR_PURCHASER_NOT_FOUND)
                NotificationManager.error("Comprador no encontrado");
            else NotificationManager.error("Error desconocido");
        }).finally(() => this.setState({loading: false}));
        return false;
    };

    documentExpireDialog = (currentDate, expireDate) => {
        if(currentDate.getTime() > expireDate.getTime()) {
            const lang = !!localStorage.getItem('lang') ? localStorage.getItem('lang') : 'es';
            let message = 'El documento ya ha expirado';
            if(lang === 'en') {
                message = 'The document is already expired';
            }
            NotificationManager.error(message);
            return true;
        } 
        return false;
    }

    render = () => {

        const {classes} = this.props;

        return (
            <div className='purchase-layout-container container pt-5 pb-3'>
                {
                    /*/ Recaptcha  instance
                    <Recaptcha/>
                    //*/
                }
                <div className="row">
                    <div className="col-sm">
                        <Typography variant="h6" color="textPrimary" className={classes.title} gutterBottom>
                            <IntlMessages id="consult"/>
                        </Typography>
                        <form onSubmit={(e) => this.onSearch(e)}>
                            <div className="form-group row">
                                <label htmlFor="queryId"
                                       className={"col-sm-4 col-form-label " + (this.state.errors.dni ? 'text-danger' : '')}><IntlMessages
                                    id='dniNumber'/></label>
                                <div className="col-sm-8">
                                    <IntlMessages id="dniNames">{text =>
                                        <input
                                            type="text" onChange={(e) => this.onChange(e.target)}
                                            className={"form-control " + (this.state.errors.dni ? 'is-invalid' : '')}
                                            id="dni"
                                            maxLength={20}
                                            name="dni" placeholder={text}/>
                                    }</IntlMessages>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="expirationDate"
                                       className={"col-sm-4 col-form-label " + (this.state.errors.expireDate ? 'text-danger' : '')}><IntlMessages
                                    id='expirationDate'/></label>
                                <div className="col-sm-8">
                                    <input
                                        type="date" id="expireDate" onChange={(e) => this.onChange(e.target)}
                                        name="expireDate"
                                        className={"form-control " + (this.state.errors.expireDate ? 'is-invalid' : '')}/>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-sm d-flex justify-content-end">
                                    <button type="submit" disabled={this.state.loading}
                                            className="btn btn-primary align-self-center"><IntlMessages
                                        id={this.state.loading ? 'searching' : 'search'}/>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-sm">
                        <PurchaserSignUp/>
                    </div>
                </div>
            </div>
        )
    }
}


export default withStyles(styles)(PurchaserLayout);
