import React, { Component, Fragment } from 'react';
// import Recaptcha from '../components/recaptcha-google';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import IntlMessages from '../lang/IntlMessages';
import IconUpload from '@material-ui/icons/CloudUpload';
import IconDelete from '@material-ui/icons/Delete';
import {
	consult,
	consultHistory,
	save,
	getBaseURL,
} from '../services/merchant-service';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { validateEmail } from '../helpers/text';

import { onSectionLoad } from './Events';

const styles = () => ({
	title: {
		fontSize: 18,
		color: '#A5A4A4',
	},
	body: {
		fontSize: 12,
		color: '#A5A4A4',
	},
});

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

const INITIAL_STATE = {
	businessName: '',
	phone: '',
	ruc: '',
	address: '',
	email: '',
	legalRepresentative: '',
	nationality: '',
	registerType: '',
	isInZLC: false,
	operationsKey: '',
	activityDescription: '',
	productsDescription: '',
	importCountries: '',
	employees: '',
	observations: '',
	notice_operations: '',
	peace_save: '',
	legal_representative_id: '',
	public_record_certificate: '',
	affidavit_legal_representative: '',
	hiring_commitment_proposal: '',
};

class MerchantLayout extends Component {
	state = {
		merchant: { ...INITIAL_STATE },
		loading: false,
		errors: {},
		consult: '',
		consultError: '',
		request: false,
		requestHistory: [],
		step: 1,
		open: false,
	};

	onChange({ value, name, files, checked }) {
		if (
			typeof files !== 'undefined' &&
			files !== null &&
			files.length > 0
		) {
			value = files[0];
		}

		if (name === 'isInZLC') value = checked;

		if (name) {
			this.setState({
				merchant: { ...this.state.merchant, [name]: value },
				errors: { ...this.state.errors, [name]: undefined },
			});
		}
	}

	onSubmitConsult = (e) => {
		e.preventDefault();
		consult(this.state.consult)
			.then((request) => {
				consultHistory(this.state.consult).then((requestHistory) =>
					this.setState({ request, requestHistory })
				);
			})
			.catch((e) => {
				if (
					e.response &&
					e.response.data.code === 'COMMERCE_REQUEST_NOT_FOUND'
				) {
					this.setState({ consultError: e.response.data.code });
				}
			});
	};
	validateErrors = () => {
		let files = [];

		switch (this.state.step) {
			case 1:
				files = [
					'businessName',
					'address',
					'email',
					'legalRepresentative',
					'nationality',
					'registerType',
					'ruc',
					'phone',
				];
				break;
			case 2:
				files = [
					'activityDescription',
					'productsDescription',
					'importCountries',
					'employees',
					'observations',
				];
				if (this.state.merchant.isInZLC) files.push('operationsKey');

				break;
			default:
				files = [
					'notice_operations',
					'peace_save',
					'legal_representative_id',
					'public_record_certificate',
					'affidavit_legal_representative',
					'hiring_commitment_proposal',
					'notice_operations',
					'peace_save',
					'legal_representative_id',
					'public_record_certificate',
					'affidavit_legal_representative',
					'hiring_commitment_proposal',
				];
				break;
		}

		let errors = 0;
		const showErrors = {};
		let notShowMessage = false;
		for (let value of files) {
			showErrors[value] = true;
			if (!this.state.merchant[value]) {
				showErrors[value] = true;
				errors++;
			} else {
				showErrors[value] = false;
			}

			if (
				value === 'email' &&
				!validateEmail(this.state.merchant[value])
			) {
				showErrors[value] = true;
				NotificationManager.error(
					'El email debe tener el formato username@example.com'
				);
				notShowMessage = true;
				errors++;
			}
			if (
				value === 'employees' &&
				(!this.state.merchant[value] ||
					isNaN(this.state.merchant[value]))
			) {
				showErrors[value] = true;
				NotificationManager.error(
					'Número de empleos debe ser un valor númerico'
				);
				notShowMessage = true;
				errors++;
			}
		}
		this.setState({ ...this.state, errors: showErrors });

		if (errors > 0) {
			if (!notShowMessage)
				if (this.state.step < 3)
					NotificationManager.error(
						'Todos los campos son requeridos'
					);
				else
					NotificationManager.error(
						'Todos los archivos son requeridos'
					);
			return false;
		}
		return true;
	};

	onSubmit = (e) => {
		e.preventDefault();

		if (!this.validateErrors()) return;

		this.setState({ ...this.state, loading: true });

		save(this.state.merchant)
			.then((result) => {
				// this.props.history.push(`/home/`);
				NotificationManager.success('Solicitud enviada con éxito');
				this.setState(
					{
						merchant: { ...INITIAL_STATE },
						step: 1,
						consult: result.data.CommerceRequestID,
					},
					() => this.toggleModal()
				);
				this.onSubmitConsult(e);
			})
			.catch((e) => {
				if (e.code === 'MISSING_REQUIRED_ARGUMENTS') {
					NotificationManager.error(
						'Todos los campos son requeridos'
					);
					return;
				}

				NotificationManager.error('Error desconocido');
			})
			.finally(() => this.setState({ ...this.state, loading: false }));

		// this.props.history.push(`/home/purchaser/${this.state.dni.trim()}?expireDate=${this.state.expireDate.trim()}`);

		return false;
	};

	toggleModal = () => {
		this.setState({ open: !this.state.open });
	};

	clean = (name) => {
		this.onChange({ name: name, value: '' });
		document.getElementById(name).value = '';
	};

	render = () => {
		onSectionLoad('merchant');
		const { classes } = this.props;

		const accept = 'application/pdf';

		let isInFinalState = null;

		return (
			<div className="merchant-layout-container container pt-5 pb-3">
				<div className="row">
					<div className="col-sm">
						{this.state.request ? (
							<Fragment>
								<Typography
									variant="h6"
									color="textPrimary"
									className={classes.title}
									gutterBottom
								>
									<IntlMessages id="request" />:{' '}
									{this.state.request.CommerceRequestID}
								</Typography>
								<table className="table table-bordered">
									<tbody>
										<tr>
											<th>
												<IntlMessages id="state" />
											</th>
											<th>
												<IntlMessages id="date" />
											</th>
										</tr>
										{this.state.requestHistory.map(
											(requestHistory) => {
												if (!isInFinalState) {
													if (
														requestHistory.status ===
															'rejected' ||
														requestHistory.status ===
															'acknowledge'
													) {
														isInFinalState = true;
													}
												}

												return (
													<tr
														key={
															requestHistory.CommerceRequestHistoryID
														}
													>
														<td>
															<IntlMessages
																id={
																	requestHistory.status
																}
															/>
														</td>
														<td>
															{moment(
																requestHistory.createdAt
															).format(
																this.props
																	.locale
																	.languageId ===
																	'spanish'
																	? 'DD-MM-YYYY hh:mm A'
																	: 'YYYY-MM-DD hh:mm A'
															)}
														</td>
													</tr>
												);
											}
										)}
									</tbody>
								</table>

								<div className="row">
									<div className="col-sm d-flex justify-content-end">
										<button
											type="button"
											onClick={() =>
												this.setState({
													request: false,
												})
											}
											className="btn btn-secondary align-self-center mr-4"
											state={{ marginRight: '20px' }}
											disabled={this.state.loading}
										>
											<IntlMessages id={'back'} />
										</button>
										{!!isInFinalState ? (
											<button
												type="button"
												onClick={() =>
													window.open(
														`${getBaseURL()}/commerces/${
															this.state.request
																.CommerceRequestID
														}/resolution`,
														'_blank'
													)
												}
												className="btn btn-primary align-self-center"
												disabled={this.state.loading}
											>
												<IntlMessages
													id={'resolution'}
												/>
											</button>
										) : null}
									</div>
								</div>
							</Fragment>
						) : (
							<form onSubmit={(e) => this.onSubmitConsult(e)}>
								<Typography
									variant="h6"
									color="textPrimary"
									className={classes.title}
									gutterBottom
								>
									<IntlMessages id="consult" />
								</Typography>
								<div className="form-group row">
									<label
										htmlFor="request_number"
										className="col-sm-4 col-form-label"
									>
										<IntlMessages id="request_number" />
									</label>
									<div className="col-sm-8">
										<input
											required
											maxLength={20}
											type="text"
											onChange={(e) =>
												this.setState({
													consult: e.target.value,
												})
											}
											className="form-control"
											id="request_number"
											name="request_number"
										/>
										{this.state.consultError && (
											<label className={'text-danger'}>
												<IntlMessages
													id={this.state.consultError}
												/>
											</label>
										)}
									</div>
								</div>
								<div className="form-group row">
									<div className="col-sm d-flex justify-content-end">
										<button
											type="submit"
											className="btn btn-primary align-self-center"
											disabled={this.state.loading}
										>
											<IntlMessages id={'search'} />
										</button>
									</div>
								</div>
							</form>
						)}
					</div>
					<div className="col-sm-7">
						<form onSubmit={(e) => this.onSubmit(e)}>
							<Typography
								variant="h6"
								color="textPrimary"
								className={classes.title}
								gutterBottom
							>
								<IntlMessages id="commercial_register" /> -{' '}
								<IntlMessages id="step" />{' '}
								{this.state.step < 3
									? this.state.step
									: 'Final'}
							</Typography>
							{this.state.step === 1 && (
								<Fragment>
									<div className={'form-group row '}>
										<label
											htmlFor="businessName"
											className={
												'col-sm-4 col-form-label ' +
												(this.state.errors.businessName
													? 'text-danger'
													: '')
											}
										>
											<IntlMessages id="business_name" />
											<span className="text-danger">
												*
											</span>{' '}
										</label>
										<div className="col-sm-8">
											<input
												required
												value={
													this.state.merchant
														.businessName
												}
												type="text"
												onChange={(e) =>
													this.onChange(e.target)
												}
												maxLength={200}
												className={
													'form-control ' +
													(this.state.errors
														.businessName
														? 'is-invalid'
														: '')
												}
												id="businessName"
												name="businessName"
											/>
										</div>
									</div>
									<div className={'form-group row '}>
										<label
											htmlFor="phone"
											className={
												'col-sm-4 col-form-label ' +
												(this.state.errors.phone
													? 'text-danger'
													: '')
											}
										>
											<IntlMessages id="phone" />
											<span className="text-danger">
												*
											</span>{' '}
										</label>
										<div className="col-sm-8">
											<input
												required
												value={
													this.state.merchant.phone
												}
												maxLength={20}
												type="text"
												onChange={(e) =>
													this.onChange(e.target)
												}
												className={
													'form-control ' +
													(this.state.errors.phone
														? 'is-invalid'
														: '')
												}
												id="phone"
												name="phone"
											/>
										</div>
									</div>
									<div className={'form-group row '}>
										<label
											htmlFor="ruc"
											className={
												'col-sm-4 col-form-label ' +
												(this.state.errors.ruc
													? 'text-danger'
													: '')
											}
										>
											<IntlMessages id="ruc" />
											<span className="text-danger">
												*
											</span>{' '}
										</label>
										<div className="col-sm-8">
											<input
												required
												value={this.state.merchant.ruc}
												type="text"
												maxLength={100}
												onChange={(e) =>
													this.onChange(e.target)
												}
												className={
													'form-control ' +
													(this.state.errors.ruc
														? 'is-invalid'
														: '')
												}
												id="ruc"
												name="ruc"
											/>
										</div>
									</div>
									<div className={'form-group row '}>
										<label
											htmlFor="address"
											className={
												'col-sm-4 col-form-label ' +
												(this.state.errors.address
													? 'text-danger'
													: '')
											}
										>
											<IntlMessages id="address" />
											<span className="text-danger">
												*
											</span>{' '}
										</label>
										<div className="col-sm-8">
											<input
												required
												value={
													this.state.merchant.address
												}
												type="text"
												onChange={(e) =>
													this.onChange(e.target)
												}
												className={
													'form-control ' +
													(this.state.errors.address
														? 'is-invalid'
														: '')
												}
												id="address"
												name="address"
											/>
										</div>
									</div>
									<div className={'form-group row '}>
										<label
											htmlFor="email"
											className={
												'col-sm-4 col-form-label ' +
												(this.state.errors.email
													? 'text-danger'
													: '')
											}
										>
											<IntlMessages id="email" />
											<span className="text-danger">
												*
											</span>{' '}
										</label>
										<div className="col-sm-8">
											<input
												required
												value={
													this.state.merchant.email
												}
												maxLength={200}
												type="email"
												onChange={(e) =>
													this.onChange(e.target)
												}
												className={
													'form-control ' +
													(this.state.errors.email
														? 'is-invalid'
														: '')
												}
												id="email"
												name="email"
											/>
										</div>
									</div>
									<div className={'form-group row '}>
										<label
											htmlFor="legalRepresentative"
											className={
												'col-sm-4 col-form-label ' +
												(this.state.errors
													.legalRepresentative
													? 'text-danger'
													: '')
											}
										>
											<IntlMessages id="legal_representative" />
											<span className="text-danger">
												*
											</span>{' '}
										</label>
										<div className="col-sm-8">
											<input
												required
												value={
													this.state.merchant
														.legalRepresentative
												}
												maxLength={200}
												type="text"
												onChange={(e) =>
													this.onChange(e.target)
												}
												className={
													'form-control ' +
													(this.state.errors
														.legalRepresentative
														? 'is-invalid'
														: '')
												}
												id="legalRepresentative"
												name="legalRepresentative"
											/>
										</div>
									</div>
									<div className={'form-group row '}>
										<label
											htmlFor="nationality"
											className={
												'col-sm-4 col-form-label ' +
												(this.state.errors.nationality
													? 'text-danger'
													: '')
											}
										>
											<IntlMessages id="nationality" />
											<span className="text-danger">
												*
											</span>{' '}
										</label>
										<div className="col-sm-8">
											<input
												required
												value={
													this.state.merchant
														.nationality
												}
												maxLength={200}
												type="text"
												onChange={(e) =>
													this.onChange(e.target)
												}
												className={
													'form-control ' +
													(this.state.errors
														.nationality
														? 'is-invalid'
														: '')
												}
												id="nationality"
												name="nationality"
											/>
										</div>
									</div>
									<div className={'form-group row '}>
										<label
											htmlFor="registerType"
											className={
												'col-sm-4 col-form-label ' +
												(this.state.errors.registerType
													? 'text-danger'
													: '')
											}
										>
											<IntlMessages id="type_of_register" />
											<span className="text-danger">
												*
											</span>{' '}
										</label>
										<div className="col-sm-8">
											<div className="form-check">
												<input
													checked={
														this.state.merchant
															.registerType ===
														'infrastructure'
													}
													maxLength={200}
													className="form-check-input"
													type="radio"
													onChange={(e) =>
														this.onChange(e.target)
													}
													id="registerTypeInfrastructure"
													value={'infrastructure'}
													name="registerType"
												/>
												<label
													className="form-check-label"
													htmlFor="registerTypeInfrastructure"
												>
													<IntlMessages id="infrastructure" />
												</label>
											</div>
											<div className="form-check">
												<input
													checked={
														this.state.merchant
															.registerType ===
														'merchant'
													}
													className="form-check-input"
													type="radio"
													onChange={(e) =>
														this.onChange(e.target)
													}
													maxLength={50}
													id="registerTypeMerchant"
													value={'merchant'}
													name="registerType"
												/>
												<label
													className="form-check-label"
													htmlFor="registerTypeMerchant"
												>
													<IntlMessages id="merchant" />{' '}
												</label>
											</div>
											<div className="form-check">
												<input
													checked={
														this.state.merchant
															.registerType ===
														'importers'
													}
													className="form-check-input"
													type="radio"
													onChange={(e) =>
														this.onChange(e.target)
													}
													id="registerTypeMerchant"
													value={'importers'}
													name="registerType"
												/>
												<label
													className="form-check-label"
													htmlFor="registerTypeMerchant"
												>
													<IntlMessages id="importers" />{' '}
												</label>
											</div>
										</div>
									</div>
									<div className={'form-group row'}>
										<label
											htmlFor="isInZLC"
											className="col-sm-4 col-form-label"
										>
											<IntlMessages id="established_in_ZNC" />{' '}
										</label>
										<div className="col-sm-8">
											<div className="form-check">
												<input
													checked={
														this.state.merchant
															.isInZLC
													}
													type="checkbox"
													onChange={(e) =>
														this.onChange(e.target)
													}
													className="form-check-input"
													id="isInZLC"
													name="isInZLC"
												/>
											</div>
										</div>
									</div>
								</Fragment>
							)}
							{this.state.step === 2 && (
								<Fragment>
									{this.state.merchant.isInZLC && (
										<div className={'form-group row '}>
											<label
												htmlFor="operationsKey"
												className={
													'col-sm-4 col-form-label ' +
													(this.state.errors
														.operationsKey
														? 'text-danger'
														: '')
												}
											>
												<IntlMessages id="operationsKey" />
												<span className="text-danger">
													*
												</span>{' '}
											</label>
											<div className="col-sm-8">
												<input
													required
													value={
														this.state.merchant
															.operationsKey
													}
													maxLength={50}
													type="text"
													onChange={(e) =>
														this.onChange(e.target)
													}
													className={
														'form-control ' +
														(this.state.errors
															.operationsKey
															? 'is-invalid'
															: '')
													}
													id="operationsKey"
													name="operationsKey"
												/>
											</div>
										</div>
									)}

									<div className={'form-group row '}>
										<label
											htmlFor="activityDescription"
											className={
												'col-sm-4 col-form-label ' +
												(this.state.errors
													.activityDescription
													? 'text-danger'
													: '')
											}
										>
											<IntlMessages id="activityDescription" />
											<span className="text-danger">
												*
											</span>{' '}
										</label>
										<div className="col-sm-8">
											<input
												required
												value={
													this.state.merchant
														.activityDescription
												}
												type="text"
												onChange={(e) =>
													this.onChange(e.target)
												}
												className={
													'form-control ' +
													(this.state.errors
														.activityDescription
														? 'is-invalid'
														: '')
												}
												id="activityDescription"
												name="activityDescription"
											/>
										</div>
									</div>

									<div className={'form-group row '}>
										<label
											htmlFor="productsDescription"
											className={
												'col-sm-4 col-form-label ' +
												(this.state.errors
													.productsDescription
													? 'text-danger'
													: '')
											}
										>
											<IntlMessages id="productsDescription" />
											<span className="text-danger">
												*
											</span>{' '}
										</label>
										<div className="col-sm-8">
											<input
												required
												value={
													this.state.merchant
														.productsDescription
												}
												type="text"
												onChange={(e) =>
													this.onChange(e.target)
												}
												className={
													'form-control ' +
													(this.state.errors
														.productsDescription
														? 'is-invalid'
														: '')
												}
												id="productsDescription"
												name="productsDescription"
											/>
										</div>
									</div>

									<div className={'form-group row '}>
										<label
											htmlFor="importCountries"
											className={
												'col-sm-4 col-form-label ' +
												(this.state.errors
													.importCountries
													? 'text-danger'
													: '')
											}
										>
											<IntlMessages id="import_countries" />
											<span className="text-danger">
												*
											</span>{' '}
										</label>
										<div className="col-sm-8">
											<input
												required
												value={
													this.state.merchant
														.importCountries
												}
												type="text"
												onChange={(e) =>
													this.onChange(e.target)
												}
												className={
													'form-control ' +
													(this.state.errors
														.importCountries
														? 'is-invalid'
														: '')
												}
												id="importCountries"
												name="importCountries"
											/>
										</div>
									</div>

									<div className={'form-group row '}>
										<label
											htmlFor="employees"
											className={
												'col-sm-4 col-form-label ' +
												(this.state.errors.employees
													? 'text-danger'
													: '')
											}
										>
											<IntlMessages id="employees" />
											<span className="text-danger">
												*
											</span>{' '}
										</label>
										<div className="col-sm-8">
											<input
												required
												value={
													this.state.merchant
														.employees
												}
												type="number"
												onChange={(e) =>
													this.onChange(e.target)
												}
												className={
													'form-control ' +
													(this.state.errors.employees
														? 'is-invalid'
														: '')
												}
												id="employees"
												name="employees"
											/>
										</div>
									</div>

									<div className={'form-group row '}>
										<label
											htmlFor="observations"
											className={
												'col-sm-4 col-form-label ' +
												(this.state.errors.observations
													? 'text-danger'
													: '')
											}
										>
											<IntlMessages id="observations" />
											<span className="text-danger">
												*
											</span>{' '}
										</label>
										<div className="col-sm-8">
											<textarea
												value={
													this.state.merchant
														.observations
												}
												onChange={(e) =>
													this.onChange(e.target)
												}
												className={
													'form-control ' +
													(this.state.errors
														.observations
														? 'is-invalid'
														: '')
												}
												rows={3}
												id="observations"
												name="observations"
											/>
										</div>
									</div>
								</Fragment>
							)}
							{this.state.step === 3 && (
								<Fragment>
									<Typography
										variant="h6"
										color="textPrimary"
										className={classes.title}
										gutterBottom
									>
										<IntlMessages id="documents" /> (PDF)
									</Typography>

									<div className="form-group row">
										<label
											htmlFor="notice_operations"
											className={
												'col-sm col-form-label ' +
												(this.state.errors
													.notice_operations
													? 'text-danger'
													: '')
											}
										>
											<IntlMessages id="notice_operations" />
											<span className="text-danger">
												*
											</span>{' '}
											{this.state.merchant
												.notice_operations &&
												` (${this.state.merchant.notice_operations.name})`}
										</label>
										<div className="col-sm-2">
											<input
												type="file"
												onChange={(e) =>
													this.onChange(e.target)
												}
												className="form-control d-none"
												id="notice_operations"
												name="notice_operations"
												accept={accept}
											/>
											<div className="d-flex flex-row flex-row-reverse">
												<label
													htmlFor="notice_operations"
													className={
														'col-form-label btn btn-sm ' +
														(this.state.merchant
															.notice_operations
															? ' btn-green'
															: ' btn-gray')
													}
												>
													<IconUpload />
												</label>
												{this.state.merchant
													.notice_operations && (
													<label
														onClick={() =>
															this.clean(
																'notice_operations'
															)
														}
														className={
															'col-form-label btn btn-sm btn-danger mr-1'
														}
													>
														<IconDelete />
													</label>
												)}
											</div>
										</div>
									</div>
									<div className="form-group row">
										<label
											htmlFor="peace_save"
											className={
												'col-sm col-form-label ' +
												(this.state.errors.peace_save
													? 'text-danger'
													: '')
											}
										>
											<IntlMessages id="peace_save" />
											<span className="text-danger">
												*
											</span>
											{this.state.merchant.peace_save &&
												` (${this.state.merchant.peace_save.name})`}
										</label>
										<div className="col-sm-2">
											<input
												type="file"
												onChange={(e) =>
													this.onChange(e.target)
												}
												className="form-control d-none"
												id="peace_save"
												name="peace_save"
												accept={accept}
											/>
											<div className="d-flex flex-row flex-row-reverse">
												<label
													htmlFor="peace_save"
													className={
														'col-form-label btn btn-sm ' +
														(this.state.merchant
															.peace_save
															? ' btn-green'
															: ' btn-gray')
													}
												>
													<IconUpload />
												</label>
												{this.state.merchant
													.peace_save && (
													<label
														onClick={() =>
															this.clean(
																'peace_save'
															)
														}
														className={
															'col-form-label btn btn-sm btn-danger mr-1'
														}
													>
														<IconDelete />
													</label>
												)}
											</div>
										</div>
									</div>
									<div className="form-group row">
										<label
											htmlFor="legal_representative_id"
											className={
												'col-sm col-form-label ' +
												(this.state.errors
													.legal_representative_id
													? 'text-danger'
													: '')
											}
										>
											<IntlMessages id="legal_representative_id" />
											<span className="text-danger">
												*
											</span>{' '}
											{this.state.merchant
												.legal_representative_id &&
												` (${this.state.merchant.legal_representative_id.name})`}
										</label>
										<div className="col-sm-2">
											<input
												type="file"
												onChange={(e) =>
													this.onChange(e.target)
												}
												className="form-control d-none"
												id="legal_representative_id"
												name="legal_representative_id"
												accept={accept}
											/>
											<div className="d-flex flex-row flex-row-reverse">
												<label
													htmlFor="legal_representative_id"
													className={
														'col-form-label btn btn-sm ' +
														(this.state.merchant
															.legal_representative_id
															? ' btn-green'
															: ' btn-gray')
													}
												>
													<IconUpload />
												</label>
												{this.state.merchant
													.legal_representative_id && (
													<label
														onClick={() =>
															this.clean(
																'legal_representative_id'
															)
														}
														className={
															'col-form-label btn btn-sm btn-danger mr-1'
														}
													>
														<IconDelete />
													</label>
												)}
											</div>
										</div>
									</div>
									<div className="form-group row">
										<label
											htmlFor="public_record_certificate"
											className={
												'col-sm col-form-label ' +
												(this.state.errors
													.public_record_certificate
													? 'text-danger'
													: '')
											}
										>
											<IntlMessages id="public_record_certificate" />
											<span className="text-danger">
												*
											</span>{' '}
											{this.state.merchant
												.public_record_certificate &&
												` (${this.state.merchant.public_record_certificate.name})`}
										</label>
										<div className="col-sm-2">
											<input
												type="file"
												onChange={(e) =>
													this.onChange(e.target)
												}
												className="form-control d-none"
												id="public_record_certificate"
												name="public_record_certificate"
												accept={accept}
											/>
											<div className="d-flex flex-row flex-row-reverse">
												<label
													htmlFor="public_record_certificate"
													className={
														'col-form-label btn btn-sm ' +
														(this.state.merchant
															.public_record_certificate
															? ' btn-green'
															: ' btn-gray')
													}
												>
													<IconUpload />
												</label>
												{this.state.merchant
													.public_record_certificate && (
													<label
														onClick={() =>
															this.clean(
																'public_record_certificate'
															)
														}
														className={
															'col-form-label btn btn-sm btn-danger mr-1'
														}
													>
														<IconDelete />
													</label>
												)}
											</div>
										</div>
									</div>
									<div className="form-group row">
										<label
											htmlFor="affidavit_legal_representative"
											className={
												'col-sm col-form-label ' +
												(this.state.errors
													.affidavit_legal_representative
													? 'text-danger'
													: '')
											}
										>
											<IntlMessages id="affidavit_legal_representative" />
											<span className="text-danger">
												*
											</span>{' '}
											{this.state.merchant
												.affidavit_legal_representative &&
												` (${this.state.merchant.affidavit_legal_representative.name})`}
										</label>
										<div className="col-sm-2">
											<input
												type="file"
												onChange={(e) =>
													this.onChange(e.target)
												}
												className="form-control d-none"
												id="affidavit_legal_representative"
												name="affidavit_legal_representative"
												accept={accept}
											/>
											<div className="d-flex flex-row flex-row-reverse">
												<label
													htmlFor="affidavit_legal_representative"
													className={
														'col-form-label btn btn-sm ' +
														(this.state.merchant
															.affidavit_legal_representative
															? ' btn-green'
															: ' btn-gray')
													}
												>
													<IconUpload />
												</label>
												{this.state.merchant
													.affidavit_legal_representative && (
													<label
														onClick={() =>
															this.clean(
																'affidavit_legal_representative'
															)
														}
														className={
															'col-form-label btn btn-sm btn-danger mr-1'
														}
													>
														<IconDelete />
													</label>
												)}
											</div>
										</div>
									</div>
									<div className="form-group row">
										<label
											htmlFor="hiring_commitment_proposal"
											className={
												'col-sm col-form-label ' +
												(this.state.errors
													.hiring_commitment_proposal
													? 'text-danger'
													: '')
											}
										>
											<IntlMessages id="hiring_commitment_proposal" />
											<span className="text-danger">
												*
											</span>{' '}
											{this.state.merchant
												.hiring_commitment_proposal &&
												` (${this.state.merchant.hiring_commitment_proposal.name})`}
										</label>
										<div className="col-sm-2">
											<input
												type="file"
												onChange={(e) =>
													this.onChange(e.target)
												}
												className="form-control d-none"
												id="hiring_commitment_proposal"
												name="hiring_commitment_proposal"
												accept={accept}
											/>
											<div className="d-flex flex-row flex-row-reverse">
												<label
													htmlFor="hiring_commitment_proposal"
													className={
														'col-form-label btn btn-sm ' +
														(this.state.merchant
															.hiring_commitment_proposal
															? ' btn-green'
															: ' btn-gray')
													}
												>
													<IconUpload />
												</label>
												{this.state.merchant
													.hiring_commitment_proposal && (
													<label
														onClick={() =>
															this.clean(
																'hiring_commitment_proposal'
															)
														}
														className={
															'col-form-label btn btn-sm btn-danger mr-1'
														}
													>
														<IconDelete />
													</label>
												)}
											</div>
										</div>
									</div>
								</Fragment>
							)}

							<div className="form-group row">
								<div className="col-sm d-flex justify-content-end">
									{this.state.step >= 2 && (
										<button
											type="button"
											onClick={() => this.next(false)}
											className="btn btn-secondary align-self-center  mr-4"
										>
											<IntlMessages id={'back'} />
										</button>
									)}

									{this.state.step === 3 ? (
										<button
											type="submit"
											className="btn btn-primary align-self-center"
											disabled={this.state.loading}
										>
											<IntlMessages
												id={
													this.state.loading
														? 'saving'
														: 'save'
												}
											/>
										</button>
									) : (
										<button
											type="button"
											onClick={() => this.next()}
											className="btn btn-primary align-self-center"
										>
											<IntlMessages id={'next'} />
										</button>
									)}
								</div>
							</div>
						</form>
					</div>
				</div>

				<Dialog
					open={this.state.open}
					TransitionComponent={Transition}
					keepMounted
					onClose={this.handleClose}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle id="alert-dialog-slide-title text-success">
						<span className="text-success">
							<IntlMessages id="successfulOperation" />
						</span>
					</DialogTitle>
					<DialogContent>
						<Typography
							variant="h6"
							color="textPrimary"
							className={classes.title}
							gutterBottom
						>
							<IntlMessages id="request_number" />:{' '}
							{this.state.consult}
						</Typography>

						<p>
							<IntlMessages id="merchant_request_number_message" />
						</p>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.toggleModal} color="primary">
							<IntlMessages id="accept" />
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	};

	next = (isNext) => {
		if (isNext !== false && !this.validateErrors()) return;

		let nextStep =
			isNext === false ? this.state.step - 1 : this.state.step + 1;
		if (nextStep < 1 || nextStep > 3) {
			nextStep = 1;
		}
		this.setState({ step: nextStep });
	};
}

function mapStateToProps({ settings: { locale } }) {
	return {
		locale,
	};
}

export default connect(mapStateToProps)(withStyles(styles)(MerchantLayout));
