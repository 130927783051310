// app config
import AppConfig from '../constants/AppConfig';
import {SET_LANGUAGE} from "../actions/types";


/**
 * initial app settings
 */
const INIT_STATE = {
    navCollapsed: AppConfig.navCollapsed,
    locale: AppConfig.locale,
    languages: AppConfig.languages,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        // set language
        case SET_LANGUAGE:
            return {...state, locale: action.payload};

        default:
            return state;
    }
}

