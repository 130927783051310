export const CENTER = {
	lat: 9.356359,
	lng: -79.897623,
};

export const ZOOM = 16;

export const PLOTS_URL = 'https://api-cpl.mici.gob.pa/plots'

export const GOOGLE_MAPS_OPTIONS = {
	id: 'google-map-script',
	googleMapsApiKey: 'AIzaSyAsoBCxuKnb5PYX1-j-Z1FY9359a6lrByA',
	libraries: ['places', 'drawing'],
};

export const GOOGLE_MAP_CONTAINER_STYLE = {
	width: '100%',
	height: '100%',
};

export const GOOGLE_MAP_POLYGON_OPTIONS = {
	fillOpacity: 0.3,
	fillColor: '#ff0000',
	strokeColor: '#ff0000',
	strokeWeight: 2,
};

export const getDefaultPath = () => {
	return [
		{ lat: 9.357296, lng: -79.899373 },
		{ lat: 9.357666, lng: -79.89423 },
		{ lat: 9.353093, lng: -79.896829 },
		{ lat: 9.357296, lng: -79.899373 },
	];
};

export const getRemotePlots = () => {
	const URL = PLOTS_URL;
	return new Promise((resolve, reject) => {
		fetch(URL)
			.then((response) => response.json())
			.then(({ plots }) => {
				resolve(plots);
			})
			.catch(reject);
	});
};

export function mapPlotProperties(row) {
	const {
		plano,
		manzana,
		calle,
		avenida,
		finca,
		inmueble,
		lote,
		tipo,
		propietario,
		condicion,
		categoria,
		area,
		rango,
		certificacion,
		codigo_de_area,
		corregimientos,
		paths,
	} = row;

	return clone({
		plan: plano,
		block: manzana,
		street: calle,
		avenue: avenida,
		estate: finca,
		property: inmueble,
		lot: lote,
		type: tipo,
		owner: propietario,
		condition: condicion,
		category: categoria,
		area,
		range: rango,
		certification: certificacion,
		areaCode: codigo_de_area,
		corrections: corregimientos,
		paths,
	});
}

export const clone = (obj) => {
	return obj ? JSON.parse(JSON.stringify(obj)) : obj;
};
