import React from "react";
import '../InformationDetails.css';
import profileImg from './nitzia.jpg';
export default () => {
    return (
        <div>
            <NitziaES />
        </div>
    );
};

const NitziaES = () => {
    return (
        <div className='authorities'>
            <img src={profileImg} alt={'Nitzia Itzel Ayarza Hughes'}/>
            <h1>Nitzia Itzel Ayarza Hughes</h1>
            <p>Nació en la ciudad de Colón un 11 de enero.</p>

            <p>
                Egresó del Colegio Abel Bravo recibiendo el diploma de Bachiller
                en Ciencias, continuó sus estudios en la Universidad de Panamá
                donde obtuvo la Licenciatura en Economía.
            </p>

            <p>
                En el año 2003 obtuvo la Especialidad en Docencia Universitaria
                en la Universidad Interamericana de Educación a Distancia de
                Panamá, posteriormente en el año 2005 logra el Postgrado en
                Comercio internacional en la Universidad Latinoamericana de
                Comercio Exterior - ULACEX, el cual le permitió hacer el énfasis
                de la Maestría en Gerencia Estratégica y logística del Negocio
                Marítimo en el 2006. Para el 2010 se titula con el grado de
                Doctora en Ciencias Económicas en la Universidad Latina de
                Panamá. Recientemente en el 2019 concluye sus estudios logrando
                la Maestría en Gerencia de Políticas Públicas con el mayor
                índice académico.
            </p>

            <p>
                Su experiencia laboral gerenciando una empresa de servicios
                marítimos por más de 15 años, y paralelamente, como docente
                universitaria con 15 años de servicio en la Universidad de
                Panamá – Centro Regional de Colón CRUC - Facultad de Economía.
            </p>

            <p>
                Gremialista por naturaleza, ha participado como miembro en la
                Cámara de Marítima de Panamá, como directora en la Cámara de
                Comercio de Colón y ha presidido el capítulo de Colón del
                Colegio de Economistas de Panamá; igualmente como relatora en el
                Diálogo Nacional por la Educación en la mesa de Inversiones; así
                también como Evaluadora Externa de Becas Internacionales en
                SENACYT.
            </p>

            <p>
                Manifiesta su interés en el bienestar colectivo participando
                activamente como miembro del Partido Revolucionario Democrático
                con 17 años de militancia en diversas comisiones locales y
                nacionales, tales como: delegada del partido en los últimos tres
                períodos electorales, miembro de la Coordinación de Campaña
                Presidencial 2014 y 2019. Encargada de Coordinar en el Frente
                Profesional y actualmente Vocera Nacional del Buen Gobierno.
            </p>
        </div>
    );
};
