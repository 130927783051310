export const REACT_SELECT_CLASSNAMES = {
	placeholder: () => {
		return 'placeholder';
	},
};

export const PAGINATION_COMPONENT_OPTIONS = {
	rowsPerPageText: 'Filas por página',
	rangeSeparatorText: 'de',
	selectAllRowsItem: true,
	selectAllRowsItemText: 'Todos',
};

export const FILTER_INITIAL_DATA = {
	property: null,
	estate: null,
	range: null,
};

export const DATA_TABLE_CUSTOM_STYLES = {
	cells: {
		style: {
			maxWidth: 100,
			paddingLeft: '10px', // override the cell padding for data cells
			paddingRight: '0px',
		},
	},
};
