import React, { useMemo } from 'react';
import { useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import './DataTable.css'

import { DataTableContext } from './DataTale.context';
import { ExpandableRow } from './ExpandableRow';
import {
	REACT_SELECT_CLASSNAMES,
	PAGINATION_COMPONENT_OPTIONS,
	FILTER_INITIAL_DATA,
	DATA_TABLE_CUSTOM_STYLES,
} from './DataTable.constants';
import { useDataTable, useGetFilterOptions } from './DataTable.hooks';

import { useGetPlots, getPropertyTitle } from '../App.hooks';

export function DataTableComponent({ lang }) {
	const [filter, setFilter] = useState({});
	const { loading, error, plots } = useGetPlots();
	const { columns, data } = useDataTable(plots, lang);

	if (loading !== false) {
		return <div className="data-table loading">Loading</div>;
	}

	if (error) {
		return <div className="data-table error">{error.message}</div>;
	}

	return (
		<DataTableContext.Provider
			value={{
				filter,
				setFilter,
				plots,
			}}
		>
			<div className="data-table">
				<div className="wrapper vertical">
					<div className="filter-bar-container">
						<FilterBar lang={lang}/>
					</div>
					<div className="data-table-container">
						<div>
							<DataTable
								columns={columns}
								data={data.filter(filterData(filter))}
								pagination
								paginationPerPage={12}
								defaultSortFieldId={1}
								customStyles={DATA_TABLE_CUSTOM_STYLES}
								expandableRows
								expandableRowsComponent={ExpandableRow}
								paginationComponentOptions={
									PAGINATION_COMPONENT_OPTIONS
								}
							/>
						</div>
					</div>
				</div>
			</div>
		</DataTableContext.Provider>
	);
}

function filterData(filter) {
	return (row) => {
		if (!Object.keys(filter).length) {
			return true;
		}
		for (const k in filter) {
			if (filter[k] && `${row[k]}` !== `${filter[k]}`) {
				return false;
			}
		}

		return true;
	};
}

function FilterBar({lang}) {
	const [isOpen, setIsOpen] = useState(false);
	const { setFilter } = useContext(DataTableContext);
	const [data, setData] = useState(FILTER_INITIAL_DATA);

	const handleChange = useMemo(() => {
		return (property) => {
			return (e) => {
				if (!e) {
					setData({ ...data, [property]: e });
					return;
				}
				const { value } = e;
				setData({ ...data, [property]: value });
			};
		}
	}, [data]);


	const options = useGetFilterOptions();


	const components = useMemo(() => {
		if (!options) {
			return null;
		}

		return Object.keys(FILTER_INITIAL_DATA).map((k) => (
			<div key={k}>
				<label htmlFor={`input-${k}`}>{getPropertyTitle(k, lang)}</label>
				<Select
					id={`input-${k}`}
					type="text"
					name={k}
					classNamePrefix="react-select"
					className={`filter-bar-input`}
					classNames={REACT_SELECT_CLASSNAMES}
					defaultValue={data[k]}
					onChange={handleChange(k)}
					options={options[k]}
					isClearable={true}
					placeholder={lang ===  'es' ? 'Seleccione' : 'Select'}
				/>
			</div>
		))
	}, [data, options, handleChange, lang])

	useEffect(() => {
		const filter = {};
		for (const k in data) {
			const value = data[k];
			if (!value) {
				continue;
			}
			filter[k] = value;
		}

		setFilter(filter);
	}, [data, setFilter]);

	const onSubmit = (e) => {
		e.preventDefault();
		const filter = {};
		for (const k in data) {
			const value = data[k];
			if (!value) {
				continue;
			}
			filter[k] = value;
		}

		setFilter(filter);
	};



	if (!options) {
		return null;
	}

	return (
		<form
			className={`filter-bar ${isOpen ? '' : 'close'}`}
			onSubmit={onSubmit}
		>
			<div className={`title`}>
				<h4>{lang === 'es' ? 'Filtros' : 'Filters'}</h4>
				<div>
					<button
						onClick={() => {
							setIsOpen(!isOpen);
						}}
					>
						{isOpen ? <UpIcon /> : <DownIcon />}
					</button>
				</div>
			</div>
			<div className="inputs">{isOpen ? components : null}</div>
		</form>
	);
}

function DownIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<polyline points="6 9 12 15 18 9"></polyline>
		</svg>
	);
}

function UpIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<polyline points="18 15 12 9 6 15"></polyline>
		</svg>
	);
}
