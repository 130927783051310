import React from 'react';
import Banner from "./Banner";

export default () => {
    return (
        <div>
            <Banner />
            <StrategicObjectivesES />
        </div>
    );
};

const StrategicObjectivesES = () => {
    return <div>
        <h1>OBJETIVOS ESTRATÉGICOS</h1>
        <ul>
            <li>
                Estimular el comercio implementando el Sistema Especial de Colón Puerto Libre y el aprovechamiento de los incentivos fiscales.
            </li>
            <li>
                Promover la generación de empleo para los Colonenses.
            </li>
            <li>
                Atraer inversiones en infraestructura y actividad comercial.
            </li>
            <li>
                Promover la construcción y restauración de edificios.
            </li>
            <li>
                Conservar y fortalecer la cultura local y el patrimonio histórico y arquitectónico de Colón.
            </li>
            <li>
                Brindar mayores beneficios en términos de mejorar la imagen y atractivos (especialmente para el turismo).
            </li>
            <li>
                Ampliar la gama y calidad de los servicios y atracciones locales.
            </li>
            <li>
                Incentivar la imagen de la ciudad de Colón a nivel Internacional a fin de crear una identidad adecuada para la inversión.
            </li>
            <li>
                Crear un ambiente propicio para recibir inversiones extranjeras apoyadas por la empresa privada local.
            </li>
            <li>
                Coordinar la implementación del Plan Maestro para la reactivación económica de la ciudad de Colón.
            </li>
            <li>
                Coordinar con las autoridades locales, la creación de condiciones adecuadas para brindar a los turistas que visitan la ciudad de Colón, un servicio de calidad mundial.
            </li>
            <li>
                Ampliar la planta hotelera, desarrollar actividades y productos turísticos atractivos, a fin de asegurar y mejorar los servicios de turismo receptivo.
            </li>
        </ul>
    </div>
};
