import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { getRemotePlots } from './App.constants';
import { db } from './Db';

export function useGetPlots() {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [plots, setPlots] = useState([]);
	useEffect(() => {
		getData()
			.then((result) => {
				setPlots(result);
			})
			.catch(setError)
			.finally(() => {
				setLoading(false);
			});
	}, []);
	return {
		error,
		loading,
		plots,
		setPlots,
	};
}

async function getData() {
	const cachedPlots = await db.plots.toArray();
	const expiration = localStorage.getItem('expiration_date');
	// I don't have anything so i will fetch remotely
	if (!cachedPlots.length || expiration === null) {
		return await getRemoteData();
	}

	const expirationDateTime = DateTime.fromISO(expiration);
	// Check if im after the expiration date
	if (DateTime.now().startOf('day') >= expirationDateTime.startOf('day')) {
		return await getRemoteData();
	}

	return await db.plots.toArray();
}

async function getRemoteData() {
	const plots = await getRemotePlots();
	console.log(`REMOTE PLOTS`, plots);
	await db.plots.clear();
	await db.plots.bulkPut(plots);
	const expirationDateTime = DateTime.now().plus({ day: 1 });
	localStorage.setItem('expiration_date', expirationDateTime.toISO());
	return plots;
}

export function capitalize(str) {
	const words = str.split(' ');

	for (let i = 0; i < words.length; i++) {
		words[i] = words[i][0].toUpperCase() + words[i].substr(1);
	}

	return words.join(' ');
}

export function getPropertyTitle(property, lang) {
	if(!lang) {
		lang = 'en';
	}
	if(lang === 'en') {
		switch (property) {
			case 'id':
				return 'ID';
			case 'plan':
				return 'Plan';
			case 'block':
				return 'Block';
			case 'street':
				return 'Street';
			case 'avenue':
				return 'Avenue';
			case 'estate':
				return 'Estate';
			case 'property':
				return 'Property';
			case 'lot':
				return 'Lot';
			case 'type':
				return 'Type';
			case 'owner':
				return 'Owner';
			case 'condition':
				return 'Condition';
			case 'category':
				return 'Category';
			case 'area':
				return 'Area m2';
			case 'range':
				return 'Range in meters';
			case 'certification':
				return 'Certification';
			case 'areaCode':
				return 'Area Code';
			case 'corrections':
				return 'Corrections';
			default:
				return property;
		}
	}

	switch (property) {
		case 'id':
			return 'ID';
		case 'plan':
			return 'Plano';
		case 'block':
			return 'Manzana';
		case 'street':
			return 'Calle';
		case 'avenue':
			return 'Avenida';
		case 'estate':
			return 'Finca';
		case 'property':
			return 'Inmueble';
		case 'lot':
			return 'Lote';
		case 'type':
			return 'Tipo';
		case 'owner':
			return 'Propietario';
		case 'condition':
			return 'Condición';
		case 'category':
			return 'Categoría';
		case 'area':
			return 'Área m2';
		case 'range':
			return 'Rango de metros';
		case 'certification':
			return 'Certificación';
		case 'areaCode':
			return 'Código de Área';
		case 'corrections':
			return 'Corregimientos';
		default:
			return property;
	}
}
