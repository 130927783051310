import AppConfig from '../constants/AppConfig'
import API from './http';

const PATH = '/commerces/';


/**
 *
 * @param merchant
 * @returns {Promise<AxiosResponse<any>>}
 */
export const save = (merchant) => {

    const formData = new FormData();
    Object.keys(merchant)
        .map(
            (key) => formData.append(key, merchant[key])
        );
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    };

    return API.post(PATH, formData, config);
};

export const consult = (requestNumber) => API.get(PATH + requestNumber).then(result => result.data);
export const consultHistory = (requestNumber) => API.get(`${PATH + requestNumber}/history`).then(result => result.data);
export const getBaseURL = () => AppConfig.apiPath;
