import 'moment/locale/es';
import './PublicLayout.css';
import * as moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';

import Home from './Home';
import IntMessage from '../lang/IntlMessages';
import LanguageProvider from '../components/LanguageProvider';
import NewsDetail from './NewsDetail';
import InformationDetails from './InformationDetails';
import { Button } from '@material-ui/core';
import Documents from './Documents';
import Maps from './maps/Maps';
import Infrastructure from './infrastructure/Infrastructure';
import List from './List';
import FeedbackContainer from './FeedbackContainer';

import govLogoUrl from '../assets/images/mici_logo.png';
import cplLogo from '../assets/images/cpl_logo.png';

import instagramLogo from '../assets/logo/instagram.png';
import twitterLogo from '../assets/logo/twitter.png';
import facebookLogo from '../assets/logo/facebook.png';
import linkedinLogo from '../assets/logo/linkedin.png';
import { Navbar, Nav, NavDropdown, Accordion } from 'react-bootstrap';


const styles = (theme) => ({
	layout: {
		width: 'auto',
		overflowY: 'hidden',
	},
	toolbarMain: {
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
		minHeight: 48,
		backgroundColor: '#F4F4F4',
	},
	toolbarContainer: {},
	flexCol: {
		flex: 1,
	},
	toolbarTime: {
		textAlign: 'center',
	},
	toolbarTitle: {
		flex: 1,
	},
	footer: {
		backgroundColor: '#F4F4F4',
		borderTop: `1px solid #707070`,
		marginTop: `200px`,
	},
	logo: {
		position: 'absolute',
		right: 0,
		top: 48,
		backgroundImage: `url(${require('../assets/bg/header_home.png')})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		width: 110,
		height: 75,
		[theme.breakpoints.up('md')]: {
			width: 208.5,
			height: 110.5,
			top: 0,
		},
	},
	sectionButton: {
		color: '#A5A4A4',
		width: '100%',
	},
	button: {
		color: '#A5A4A4',
	},
	middle: {
		[theme.breakpoints.up(528 + theme.spacing.unit * 3 * 2)]: {
			borderLeft: `1px solid `,
			borderColor: '#707070',
		},
	},
	addresses: {
		[theme.breakpoints.up(528 + theme.spacing.unit * 3 * 2)]: {
			borderRight: `1px solid`,
			borderLeft: `1px solid `,
			borderColor: '#707070',
		},
	},
	socialNetworkIcon: {
		width: 25,
		height: 25,
	},
	socialNetworkLink: {
		'margin-right': 10,
	},
	footerTitle: {
		fontSize: 18,
		color: '#848484',
		fontWeight: 'regular',
	},
	footerText: {
		fontSize: 12,
		color: '#A5A4A4',
	},
	footerTextReserved: {
		fontSize: 8,
		color: '#A5A4A4',
		fontWeight: 100,
	},
	language: {
		marginRight: 100,
		[theme.breakpoints.up('md')]: {
			marginRight: 184,
		},
	},
	title: {
		fontSize: 18,
		color: '#A5A4A4',
	},
	bannerContainer: {
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
		minHeight: 80,
		color: '#FAFAFA',
		display: 'flex',
		height: 100,
		backgroundColor: '#053776',
		textAlign: 'center',
	},
	sectionContainer: {
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
		minHeight: 70,
		color: '#686c6f',
		display: 'flex',
		height: 100,
		backgroundColor: '#FAFAFA',
		marginLeft: 100,
		marginRight: 100,
	},
	sectionContentContainer: {
		display: 'flex',
	},
	sectionContentCol: {
		flex: 1,
		textAlign: 'center',
		paddingRight: 20,
		paddingLeft: 20,
	},
});

localStorage.setItem('lang', 'es');
moment.locale('es');

function formatDate(date) {
	const words = date.split(' ');
	const response = [];
	for (let word of words) {
		if (word.length <= 2) {
			response.push(word);
		} else {
			response.push(word[0].toUpperCase() + word.slice(1));
		}
	}
	return response.join(' ');
}

function PublicLayout(props) {
	const { classes } = props;

	const [section, setSection] = useState();
	const [open, setOpen] = useState(false);
	const [time, setTime] = useState(
		moment(new Date()).format('dddd, MMMM Do YYYY, h:mm:ss a')
	);
	setInterval(() => {
		const lang = localStorage.getItem('lang');
		setTime(
			formatDate(
				moment(new Date())
					.locale(lang)
					.format('dddd, MMMM Do YYYY, h:mm:ss a')
			)
		);
	}, 1000);

	const getSectionClasses = (sectionName) =>
		section === sectionName ? `section-${sectionName}-active` : '';

	useEffect(() => {
		document.addEventListener('section-change', (e) => {
			let currentSection;
			switch (props.location.pathname) {
				case '/home':
					currentSection = 'home';
					break;
				case '/home/purchaser':
					currentSection = 'purchaser';
					break;
				case '/home/merchant':
					currentSection = 'merchant';
					break;
				default:
					currentSection = e.detail.section;
			}
			setSection(currentSection);
		});
		return () => {
			document.removeEventListener('section-change')
		}
	}, [])

	return (
		<Fragment>
			<CssBaseline />
			<div className={classes.layout}>
				<Toolbar className={`toolbar-time-container`}>
					<div className={`${classes.toolbarTime}`}>
						{time.replace(/am/i, 'AM').replace(/pm/i, 'PM')}
					</div>
					<div>
						<LanguageProvider
							onLanguageChange={(lang) => {
								localStorage.setItem('lang', lang);
								setTime(
									formatDate(
										moment(new Date())
											.locale(lang)
											.format(
												'dddd, MMMM Do YYYY, h:mm:ss a'
											)
									)
								);
							}}
						/>
					</div>
				</Toolbar>
				<Toolbar className={`toolbar-banner-container`}>
					<div>
						<img alt={'Colon Puerto Libre Logo'} src={cplLogo} />
					</div>
					<div>
						<img alt={'MICI Logo'} src={govLogoUrl} />
					</div>
				</Toolbar>
				<Toolbar className="toolbar-section-container-mobile">
					<MobileNavBar setOpen={setOpen} />
				</Toolbar>
				<Toolbar className="toolbar-section-container-desktop">
					<DesktopNavBar
						getSectionClasses={getSectionClasses}
						setOpen={setOpen}
					/>
				</Toolbar>
				<main>
					<Switch>
						<Redirect from="/" to="/home" exact />
						<Route
							path="/home"
							render={(props) => <Home {...props} />}
						/>
						<Route
							path="/news/:id"
							render={(props) => <NewsDetail {...props} />}
						/>
						<Route
							path="/event/:id"
							render={(props) => <NewsDetail {...props} />}
						/>
						<Route
							path={`/documents`}
							render={(props) => <Documents {...props} />}
						/>
						<Route 
							path={'/infrastructure'}
							render={(props) => <Infrastructure {...props} />}
						/>
						<Route
							path={`/maps`}
							render={(props) => <Maps {...props} />}
						/>
						<Route
							path={`/list/:type`}
							render={(props) => <List {...props} />}
						/>
						<Route
							path="/information/:id"
							render={(props) => (
								<InformationDetails {...props} />
							)}
						/>
					</Switch>
				</main>

				<FeedbackContainer open={open} setOpen={setOpen} />
			</div>
			{/* Footer */}
			<footer className={classes.footer}>
				<div className="container">
					<div className="row">
						<div className="col-sm pt-3 pb-3">
							<Typography
								variant="h6"
								color="textPrimary"
								className={classes.footerTitle}
								gutterBottom
							>
								<IntMessage id="link_of_interest" />
							</Typography>
							<div className="">
								<div className="d-flex flex-row justify-content-between pr-lg-4 pt-2">
									<Link to={'/documents'}>
										<Typography
											variant="h6"
											color="textSecondary"
											className={classes.footerText}
											gutterBottom
										>
											<IntMessage id="documentsTitle" />
										</Typography>
									</Link>
								</div>
							</div>
						</div>
						<div
							className={classnames(
								'col-sm  pt-3 pb-3',
								classes.middle
							)}
						>
							<Typography
								variant="h6"
								color="textPrimary"
								className={classes.footerTitle}
								gutterBottom
							>
								<IntMessage id="contact" />
							</Typography>
							<Typography
								variant="h6"
								color="textSecondary"
								className={classes.footerText}
								gutterBottom
							>
								Tel: 475-4704/475-4706
							</Typography>
							<Typography
								variant="h6"
								color="textSecondary"
								className={classes.footerText}
								gutterBottom
							>
								Email: colonpuertolibre@mici.gob.pa
							</Typography>
						</div>
						<div
							className={classnames(
								'col-sm  pt-3 pb-3',
								classes.addresses
							)}
						>
							<Typography
								variant="h6"
								color="textPrimary"
								className={classes.footerTitle}
								gutterBottom
							>
								<IntMessage id="addresses" />
							</Typography>

							<Typography
								variant="body1"
								color="textSecondary"
								className={classes.footerText}
								gutterBottom
							>
								<IntMessage id="address_1" />
							</Typography>
							<br />
							<Typography
								variant="body1"
								color="textSecondary"
								className={classes.footerText}
								gutterBottom
							>
								<IntMessage id="address_2" />
							</Typography>
						</div>
						<div className="col-sm pt-3 pb-3">
							<Typography
								variant="h6"
								color="textPrimary"
								className={classes.footerTitle}
								gutterBottom
							>
								<IntMessage id="followUs" />
							</Typography>

							<a
								href="https://www.instagram.com/colonpuertolibre_cpl/"
								className={classes.socialNetworkLink}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									alt={'Colon Puerto Libre Instagram'}
									className={classes.socialNetworkIcon}
									src={instagramLogo}
								/>
							</a>
							<a
								href="https://twitter.com/colonpuertolibr"
								className={classes.socialNetworkLink}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									alt={'Colon Puerto Libre Twitter'}
									className={classes.socialNetworkIcon}
									src={twitterLogo}
								/>
							</a>
							<a
								href="https://www.facebook.com/Colonpuertolibr"
								className={classes.socialNetworkLink}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									alt={'Colon Puerto Libre Facebook'}
									className={classes.socialNetworkIcon}
									src={facebookLogo}
								/>
							</a>
							<a
								href="http://linkedin.com/in/col%C3%B3n-puerto-libr-11242220a"
								className={classes.socialNetworkLink}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									alt={'Colon Puerto Libre LinkedIn'}
									className={classes.socialNetworkIcon}
									src={linkedinLogo}
								/>
							</a>
							<Typography
								variant="h6"
								color="textSecondary"
								className={classnames(
									classes.footerTextReserved,
									'pt-4 text-justify'
								)}
								gutterBottom
							></Typography>
						</div>
					</div>
				</div>
			</footer>
			{/* End footer */}
		</Fragment>
	);
}

PublicLayout.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PublicLayout);

function MobileNavBar({ setOpen }) {
	const lang = localStorage.getItem('lang');
	const sections = {
		en: {
			home: 'Home',
			information: 'Information',
			purchasers: 'Purchasers',
			merchants: 'Merchants',
			contactUs: 'Contact Us',
			documents: 'Documents',
			maps: 'Maps',
			infrastructure: 'Infrastructure',
			administration: 'Administration',
			informationDetails: {
				history: 'Historical Review',
				mission: 'Mission/Vision',
				objectives: 'Strategic Objectives',
				framework: 'Regulatory Framework',
				organizationChart: 'Organization Chart',
				authorities: 'Authorities',
				adBoard: 'Advisory Board',
			},
		},
		es: {
			home: 'Inicio',
			information: 'Información',
			purchasers: 'Compradores',
			merchants: 'Comercios',
			contactUs: 'Contáctanos',
			documents: 'Documentos',
			maps: 'Mapas',
			infrastructure: 'Infraestructura',
			administration: 'Administración',
			informationDetails: {
				history: 'Reseña Histórica',
				mission: 'Misión/Visión',
				objectives: 'Objetivos Estratégicos',
				framework: 'Marco Normativo',
				organizationChart: 'Organigrama',
				authorities: 'Autoridades',
				adBoard: 'Junta Asesora',
			},
		},
	};

	const {
		home,
		information,
		purchasers,
		merchants,
		contactUs,
		documents,
		maps,
		administration,
		informationDetails,
		infrastructure
	} = sections[lang];

	const {
		history,
		mission,
		objectives,
		framework,
		organizationChart,
		authorities,
		adBoard,
	} = informationDetails;

	return (
		<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
			<Navbar.Brand href="/home">{home}</Navbar.Brand>
			<Navbar.Toggle aria-controls="responsive-navbar-nav" />
			<Navbar.Collapse id="responsive-navbar-nav">
				<Nav className="mr-auto">
					<NavDropdown
						title={information}
						id="collasible-nav-dropdown"
					>
						<NavDropdown.Item href="/information/historical_review">
							{history}
						</NavDropdown.Item>
						<NavDropdown.Item href="/information/mission_vision">
							{mission}
						</NavDropdown.Item>
						<NavDropdown.Item href="/information/strategic_objectives">
							{objectives}
						</NavDropdown.Item>
						<NavDropdown.Item href="/information/regulatory_framework">
							{framework}
						</NavDropdown.Item>
						<NavDropdown.Item href="/information/organization_chart">
							{organizationChart}
						</NavDropdown.Item>
						<NavDropdown.Item href="/information/authorities">
							{authorities}
						</NavDropdown.Item>
						<NavDropdown.Item href="/information/advisory_board">
							{adBoard}
						</NavDropdown.Item>
					</NavDropdown>
					<Nav.Link href="/home/purchaser">{purchasers}</Nav.Link>
					<Nav.Link href="/home/merchant">{merchants}</Nav.Link>
					<Nav.Link href="#" onClick={() => setOpen(true)}>
						{contactUs}
					</Nav.Link>
					<Nav.Link href="/documents">{documents}</Nav.Link>
					<Nav.Link href="/maps">{maps}</Nav.Link>
					<Nav.Link href="/infrastructure">{infrastructure}</Nav.Link>
					<Nav.Link href="https://cpl-admin.mici.gob.pa/">
						{administration}
					</Nav.Link>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
}

function DesktopNavBar({ getSectionClasses, setOpen }) {
	const isInformationSection = /^\/information/.test(window.location.pathname);
	// console.log(`Is information section: ${isInformationSection}`);

	const [activeKey, setActiveKey] = useState(isInformationSection ? 0 : 1);
	
	return (
		<Accordion
			activeKey={`${activeKey}`}
			className="toolbar-section-accordion-container"
		>
			<div className="toolbar-section">
				<div>
					<div>
						<Button
							component={Link}
							to={'/home'}
							onClick={() => {
								setActiveKey(1);
							}}
							className={`toolbar-section-button`}
						>
							<span
								className={`d-none d-md-block ${getSectionClasses(
									'home'
								)}`}
							>
								{' '}
								<IntMessage id={'home'} />
							</span>
						</Button>
					</div>
				</div>
				<div>
					<div>
						<Accordion.Toggle
							as={Button}
							variant="link"
							eventKey="0"
							onClick={() => {
								setActiveKey(activeKey === 1 ? 0 : 1);
							}}
							className={`toolbar-section-button`}
						>
							<span
								className={`d-none d-md-block ${getSectionClasses(
									'information'
								)}`}
							>
								{' '}
								<IntMessage id={'information'} />
							</span>
						</Accordion.Toggle>
					</div>
				</div>
				<div>
					<div>
						<Button
							component={Link}
							to={'/home/purchaser'}
							onClick={() => {
								setActiveKey(1);
							}}
							className={`toolbar-section-button`}
						>
							<span
								className={`d-none d-md-block ${getSectionClasses(
									'purchaser'
								)}`}
							>
								{' '}
								<IntMessage id={'purchasers'} />
							</span>
						</Button>
					</div>
				</div>
				<div>
					<div>
						<Button
							component={Link}
							to={'/home/merchant'}
							onClick={() => {
								setActiveKey(1);
							}}
							className={`toolbar-section-button`}
						>
							<span
								className={`d-none d-md-block ${getSectionClasses(
									'merchant'
								)}`}
							>
								{' '}
								<IntMessage id={'merchants'} />
							</span>
						</Button>
					</div>
				</div>
				<div>
					<div>
						<Button
							onClick={() => setOpen(true)}
							className={`toolbar-section-button`}
						>
							<span
								className={`d-none d-md-block`}
							>
								{' '}
								<IntMessage id={'contact_us'} />
							</span>
						</Button>
					</div>
				</div>
				<div>
					<div>
						<Button
							component={Link}
							to={'/documents'}
							onClick={() => {
								setActiveKey(1);
							}}
							className={`toolbar-section-button`}
						>
							<span
								className={`d-none d-md-block ${getSectionClasses(
									'document'
								)}`}
							>
								{' '}
								<IntMessage id={'documents'} />
							</span>
						</Button>
					</div>
				</div>
				<div>
					<div>
						<Button
							component={Link}
							to={'/maps'}
							onClick={() => {
								setActiveKey(1);
							}}
							className={`toolbar-section-button`}
						>
							<span
								className={`d-none d-md-block ${getSectionClasses(
									'maps'
								)}`}
							>
								{' '}
								<IntMessage id={'maps'} />
							</span>
						</Button>
					</div>
				</div>
				<div>
					<div>
						<Button
							component={Link}
							to={'/infrastructure'}
							onClick={() => {
								setActiveKey(1);
							}}
							className={`toolbar-section-button`}
						>
							<span
								className={`d-none d-md-block ${getSectionClasses(
									'infrastructure'
								)}`}
							>
								{' '}
								<IntMessage id={'infrastructure'} />
							</span>
						</Button>
					</div>
				</div>
				<div>
					<div>
						<Button
							href={'https://cpl-admin.mici.gob.pa/'}
							className={`toolbar-section-button`}
						>
							<span className={`d-none d-md-block`}>
								{' '}
								<IntMessage id={'administration'} />
							</span>
						</Button>
					</div>
				</div>
			</div>

			<Accordion.Collapse eventKey="0" style={{ width: '100%' }}>
				<div className="toolbar-section-information">
					<div>
						<div>
							<Button
								component={Link}
								to={'/information/historical_review'}
								className={`toolbar-section-button information`}
							>
								<span
									className={`d-none d-md-block toolbar-section-information-button`}
								>
									{' '}
									<IntMessage id={'historical_review'} />
								</span>
							</Button>
						</div>
					</div>
					<div>
						<div>
							<Button
								component={Link}
								to={'/information/mission_vision'}
								className={`toolbar-section-button information`}
							>
								<span
									className={`d-none d-md-block toolbar-section-information-button`}
								>
									{' '}
									<IntMessage id={'mission_vision'} />
								</span>
							</Button>
						</div>
					</div>
					<div>
						<div>
							<Button
								component={Link}
								to={'/information/strategic_objectives'}
								className={`toolbar-section-button information`}
							>
								<span
									className={`d-none d-md-block toolbar-section-information-button`}
								>
									{' '}
									<IntMessage id={'strategic_objectives'} />
								</span>
							</Button>
						</div>
					</div>
					<div>
						<div>
							<Button
								component={Link}
								to={'/information/regulatory_framework'}
								className={`toolbar-section-button information`}
							>
								<span
									className={`d-none d-md-block toolbar-section-information-button`}
								>
									{' '}
									<IntMessage id={'regulatory_framework'} />
								</span>
							</Button>
						</div>
					</div>
					<div>
						<div>
							<Button
								component={Link}
								to={'/information/organization_chart'}
								className={`toolbar-section-button information`}
							>
								<span
									className={`d-none d-md-block toolbar-section-information-button`}
								>
									{' '}
									<IntMessage id={'organization_chart'} />
								</span>
							</Button>
						</div>
					</div>
					<div>
						<div>
							<Button
								component={Link}
								to={'/information/authorities'}
								className={`toolbar-section-button information`}
							>
								<span
									className={`d-none d-md-block toolbar-section-information-button`}
								>
									{' '}
									<IntMessage id={'authorities'} />
								</span>
							</Button>
						</div>
					</div>
					<div>
						<div>
							<Button
								component={Link}
								to={'/information/advisory_board'}
								className={`toolbar-section-button information`}
							>
								<span
									className={`d-none d-md-block  toolbar-section-information-button`}
								>
									{' '}
									<IntMessage id={'advisory_board'} />
								</span>
							</Button>
						</div>
					</div>
				</div>
			</Accordion.Collapse>
		</Accordion>
	);
}
