import API from './http';
import AppConfig from "../constants/AppConfig";

const PATH = '/contents/';


export const getContents = () => API.get(PATH).then(result => result.data);


export const getContent = (id) => API.get(PATH + id).then(result => result.data);

export const getCover = (id) => `${AppConfig.apiPath + PATH + id}/cover`;

export const getThumbnail = (id) => `${AppConfig.apiPath + PATH + id}/thumbnail`;
