import React from 'react';
import {connect} from 'react-redux';
import {IntlProvider} from 'react-intl';
import AppLocale from '../lang';
import PublicLayout from "./PublicLayout";
import {Route} from 'react-router-dom';


const MainApp = ({locale}) => {

    const currentAppLocale = AppLocale[locale.locale];
    return (
        <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            <Route path="/" component={PublicLayout}/>
        </IntlProvider>
    )
};

const mapStateToProps = ({auth, settings}) => {
    return {auth, locale: settings.locale};
};

export default connect(mapStateToProps)(MainApp);
