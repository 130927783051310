import React from "react";
import '../InformationDetails.css';
import Banner from './Banner';

export default () => {
    return <div> 
        <Banner/>
        <HistoricalReviewES />
    </div>
};

const HistoricalReviewES = () => {
    return (
        <div>
            <h1>RESEÑA HISTÓRICA</h1>

            <h2>Ley 29 de 30 de diciembre de 1992</h2>
            <p>
                A través de la Ley 29 de 30 de diciembre de 1992, se estableció
                un sistema fiscal y aduanero especial con estructura de Puerto
                Libre aplicable a la Provincia de Colón, sujeto a la
                fiscalización y controles del Órgano Ejecutivo. Dicha Ley
                delimitó el área circunscrita inicialmente para Isla Manzanillo
                (16 calles de ciudad de Colón que conforma el Casco Antiguo de
                Colón), asimismo el artículo previó el mecanismo de delimitación
                o ampliación de otras áreas.
            </p>
            <p>
                La ley fue reglamentada y normada para dar paso al área conocida
                como Colón 2000 (Puerto de Cruceros), pero no fue puesto en
                marcha para la Isla Manzanillo.
            </p>
            <p>
                Los beneficiarios de la Ley 29 de 1992 estaban circunscritos a
                los turistas y ciudadanos que vivieran en el área delimitada de
                Puerto Libre. Siendo así, fue solamente supeditada para los
                turistas de Colón 2000 y limitó su sostenibilidad al tráfico de
                Cruceros.
            </p>
            <p>
                Los alicientes de dicha Ley 29 eran la introducción y compra de
                mercancías y productos exentas de arancel de importación (0%
                importación), dando como diagnóstico que la legislación estaba
                desprovista de incentivos para la inversión privada.
            </p>
            <p>
                Transcurridos más de 25 años desde la promulgación de la Ley 29
                de 1992 (que no logró implementarse para la Isla Manzanillo), se
                dispuso a realizar una análisis y diagnóstico que buscaba
                sumarse a las medidas de una estrategia integral de Gobierno que
                tiene como norte mejorar la calidad de vida de los colonenses
                por medio de una planificación de desarrollo social, inversión
                en infraestructura, educación, seguridad y desarrollo económico.
            </p>
            <p>
                El proyecto de ley que pretendía modificar la Ley 29 de 1992
                mantiene su esencia y mantiene la implementación de dicho
                sistema especial al área de las 16 calles de Colón (Isla
                Manzanillo), pero a su vez con parámetros generales que
                permitieran reglamentar la efectiva implementación, desarrollo,
                control y fiscalización del Sistema Especial de Puerto Libre en
                las 16 calles de Colón, apuntalando a la sostenibilidad del
                sistema y dirigido a aumentar la comercialización, el desarrollo
                económico, cultural y turístico de la Provincia de Colón.
            </p>

            <h2>Ley 7 de 4 de abril de 2016</h2>
            <p>
                A través de la Ley 7 de 4 de abril de 2016 se modificó la Ley 29
                de 1992, y se creó una nueva plataforma legal con un régimen
                comercial, cultural y turístico para Colón.
            </p>
            <p>
                Con la modificación del Sistema Especial de Puerto Libre para la
                provincia de Colón:
            </p>
            <ul>
                <li>
                    Colón Puerto Libre se sumó a una estrategia integral para
                    reactivar económicamente a la ciudad de Colón.
                </li>
                <li>
                    Se buscaba impulsar la inversión privada para que acompañe
                    la inversión pública, replicando el esquema del Casco
                    Antiguo de Panamá.
                </li>
                <li>
                    Permitió a los panameños y extranjeros residentes comprar
                    artículos sin impuesto de importación hasta US$ 1,000
                    semestrales, no transferible ni acumulables en un mismo
                    periodo, pero acumulable por familia hasta los $2,000 dentro
                    del mismo semestre.
                </li>
                <li>
                    Estableció que los turistas no tienen límite de compra y
                    además están exentos del ITBMS.
                </li>
                <li>
                    Se incrementaron las visitas a la provincia de Colón,
                    generando más empleos, promoviendo la cultura colonense y
                    mejorando su calidad de vida.
                </li>
                <li>
                    Colón Puerto Libre pretende dar sostenibilidad a las
                    inversiones públicas que se den en el área.
                </li>
                <li>
                    Para el tema de los incentivos, la ley de puerto libre
                    remitía a la ley vigente para los incentivos del casco
                    antiguo de la ciudad de Panamá (Ley 136 de 2013.
                </li>
                <li>
                    Promovió la contratación de mano de obra colonense ya que
                    para recibir incentivos el inversionista debe de
                    comprometerse a la contratación de mano de obra colonense.
                </li>
            </ul>

            <p>
                Aun cuando las nuevas modificaciones procuran fomentar la
                inversión privada en la ciudad de Colón, dotando al sistema de
                una serie de incentivos que promueven su desarrollo homologando
                lo ocurrido en el Casco Antiguo de Panamá, es necesario
                optimizar el Sistema Especial de Puerto Libre, en principio
                excluyendo el cobro del Impuesto a la Transferencia de Bienes
                Corporales Muebles y la Prestación de Servicios (ITBMS) y del
                impuesto Selectivo al Consumo (ISC) permitiendo que las 16
                calles (incluyendo Colón 2000) sean totalmente libres de cobro
                de impuestos tanto para nacionales como extranjeros.
            </p>
            <p>
                Adicionalmente se procura dentro de la optimización, que se
                otorguen créditos fiscales para inversionistas comerciantes
                registrados en el Sistema Especial de Puerto Libre para la
                Provincia de Colon, que aspiren a vender dentro del Sistema
                Especial de Puerto Libre para la Provincia de Colon, mercancía
                sobre la cual se hayan pagado los impuestos de introducción al
                país, es decir que ya haya sido nacionalizada).
            </p>

            <h2>Ley 60 de 5 de noviembre de 2018</h2>
            <p>
                La Ley 60 de 5 de noviembre de 2018 modificó la Ley 7 de 2016 y
                la Ley 29 de 1992 de manera tal que se excluye el cobro tanto
                del Impuesto a la Transferencia de Bienes Corporales Muebles y
                la Prestación de Servicios (ITBMS) y del impuesto Selectivo al
                Consumo (ISC), y que se otorguen créditos fiscales, como
                incentivo para fortalecer la inversión comercial en las 16
                calles de la ciudad de Colón, fomentando el efecto lastre en el
                resto de la Provincia de colón.
            </p>
            <p>
                En aras de aprovechar dicha modificación a la mencionada excerta
                legal, se transcriben los procedimientos, beneficios,
                incentivos, requisitos y sanciones establecidos en la Ley 136 de
                2013 “Que actualiza el régimen fiscal aplicable para la
                rehabilitación y puesta en valor del conjunto monumental
                histórico del Casco Antiguo de la Ciudad de Panamá, toda vez que
                el artículo 12-A de la Ley N° 29 de 1992 únicamente indicaba que
                los mismos son aplicables para el Sistema Especial de Puerto
                Libre para la Provincia de Colon, más no los detallaba.
            </p>
        </div>
    );
};
