import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import T from '../lang/IntlMessages';
import classnames from 'classnames';
import {connect} from "react-redux";
import moment from "moment";

const styles = () => ({
    card: {
        borderColor: '#707070'
    },

    title: {
        color: '#C4C4C4',
        backgroundColor: 'white',
        fontSize: 16,
        fontWeight: 400,
        borderColor: '#707070'
    },

    footer: {
        color: '#C4C4C4',
        fontSize: 8,
        fontWeight: 400
    },

    description: {
        color: '#C4C4C4',
        fontSize: 12,
        fontWeight: 400
    },
    item: {
        borderColor: '#707070',
        minHeight: 80
    }
});

const HomeAnnouncement = (props) => {
    const {locale, title: {[locale]: title}, abstract, startDate, classes} = props;
    const description = abstract ? abstract[locale] : '';
    const date = startDate ? moment(startDate).format("DD/MM/YYYY") : '';

    return (
        <div className={classnames("card rounded-0", classes.card)}>
            {title && (<div className={classnames("card-header pl-2", classes.title)}>
                {title}
            </div>)}

            <ul className="list-group list-group-flush">
                <li className={classnames("d-flex flex-column justify-content-between list-group-item pl-2", classes.item)}>
                    <Typography component="p" className={classes.description}>
                        {description}
                    </Typography>
                    <Typography component="p" className={classes.footer}>
                        <T id='published'/> - {date}
                    </Typography>
                </li>
            </ul>
        </div>


    );
};

const mapStateToProps = ({settings: {locale: {locale}}}) => {
    return {locale};
};
export default connect(mapStateToProps)(withStyles(styles)(HomeAnnouncement));
