import { createContext } from 'react';

export const MapViewerContext = createContext({
	map: {},
	setMap: () => {},
	plots: [],
	plot: null,
	setPlot: () => {},
	position: null,
	setPosition: () => {},
});
