import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import {connect} from "react-redux";
import IntlMessages from "../lang/IntlMessages";
import {getContents} from "../services/content-service";
import HomeNewsComponent from "../components/home-news";
import HomeAnnouncement from "../components/home-announcement";

const CONTENT_KEY = 'CONTENT_KEY';
const styles = theme => ({
    title: {
        fontSize: 18,
        color: '#A5A4A4',
        marginTop: '5%',
        marginBottom: 50,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    headerContainer: {
        flex: 1,
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    }
});


class List extends Component {
    state = {
        expanded: null,
        content: []
    };

    /**
     *
     * @param content array
     */
    setContent = (content) => {
        if (!content) return;
        let type = '';
        switch (this.props.match.params.type) {
            case 'events':
                type = 'event';
                break;
            case 'announcements':
                type = 'announcement';
                break;
            default:
                type = 'news';
        }
        this.setState({
            content: content.filter(item => item.type === type),
        })
    };

    componentDidMount() {
        const prevContent = localStorage.getItem(CONTENT_KEY);
        window.scrollTo(0, 0);
        this.setContent(JSON.parse(prevContent));
        getContents().then(content => {
            localStorage.setItem(CONTENT_KEY, JSON.stringify(content));
            this.setContent(content)
        }).catch(e => console.log('error', e));
    }

    render() {
        const {classes} = this.props;

        return (
            <div className="container">
                <Typography variant="h6" color="textPrimary" className={classes.title} gutterBottom>

                    <IntlMessages id={this.props.match.params.type}/>
                </Typography>

                <div className="row">
                    {this.state.content.map((item) => {
                        switch (this.props.match.params.type) {
                            case 'events':
                                return (<div className="col-sm-6 pb-2" key={item.id}>
                                    <HomeNewsComponent {...item}/>
                                </div>);
                            case 'announcements':
                                return (<div className="col-sm-3 pb-2" key={item.id}>
                                    <HomeAnnouncement {...item}/>
                                </div>);
                            default:
                                return (<div className="col-sm-6 pb-2" key={item.id}>
                                    <HomeNewsComponent followLink {...{
                                        ...item,
                                        description: item.resume,
                                        link: `/news/${item.id}`
                                    }}/>
                                </div>);
                        }
                    })}
                    {(!this.state.content || this.state.content.length === 0) && (
                        <Typography variant="p" color="textPrimary" className={classes.title} gutterBottom>
                            <IntlMessages id="empty"/>
                        </Typography>
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps({settings: {locale}}) {
    return {
        locale
    }
}


List.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(List));
