import { useContext, useEffect, useState } from 'react';

import { DataTableContext } from './DataTale.context';
import { clone, getRemotePlots } from '../App.constants';
import { getPropertyTitle } from '../App.hooks';

const NUMERIC_PROPERTIES = new Set([
	'block',
	'lot',
	'estate',
	'area',
	'areaCode',
	'property',
]);

const TEXT_PROPERTIES = new Set(['range', 'condition', 'owner']);

const VALID_COLUMN_PROPERTIES = new Set([
	'condition',
	'owner',
	'category',
	'block',
	'lot',
	'street',
	'avenue',
]);

/* React Hook that returns the data of the plots*/
export function useGetPlots() {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [plots, setPlots] = useState([]);
	useEffect(() => {
		getRemotePlots()
			.then(setPlots)
			.catch(setError)
			.finally(() => {
				setLoading(false);
			});
	}, []);
	return {
		error,
		loading,
		plots,
	};
}

/* Hook that maps the data to the format use by data table */
export function useDataTable(plots, lang) {
	const [columns, setColumns] = useState([]);
	const [data, setData] = useState([]);

	if(!lang) {
		lang = 'es';
	}

	useEffect(() => {
		if (!plots.length) {
			setColumns([]);
			setData([]);
			return;
		}
		const [plot] = plots;
		const columns = Object.entries(plot).filter(([k]) => {
			return VALID_COLUMN_PROPERTIES.has(k);
		});
		const columnsTitle = columns.map(mapDataTableTitle(lang));
		setColumns(columnsTitle);
		const result = clone(plots.map(mapDataTableData)).map(plot => {
			plot.lang = lang;
			return plot;
		});
		setData(result);
	}, [plots, lang]);

	return {
		columns,
		data,
	};
}

/* Hook that returns the options based on the data */
export function useGetFilterOptions() {
	const { plots } = useContext(DataTableContext);
	const [options, setOptions] = useState(null);

	useEffect(() => {
		if (!plots.length) {
			return;
		}
		const response = {};
		const properties = Object.keys(plots[0]);
		for (const plot of plots) {
			for (const property of properties) {
				// If the set for the property doesn't exist  add it
				if (!response[property]) {
					response[property] = new Set();
				}
				const value = plot[property];
				if (value !== null) {
					response[property].add(value);
				}
			}
		}

		const result = {};
		for (const property in response) {
			if (TEXT_PROPERTIES.has(property)) {
				response[property] = [...response[property]].sort();
			}

			if (NUMERIC_PROPERTIES.has(property)) {
				response[property] = [...response[property]]
					.map((value) => {
						if (NUMERIC_PROPERTIES.has(property)) {
							return parseFloat(`${value}`);
						}
						return value;
					})
					.sort((a, b) => a - b);
			}

			result[property] = [...response[property]].map((value) => {
				let val = `${value}`;
				if (NUMERIC_PROPERTIES.has(property)) {
					val = parseFloat(val);
				} else if (property === 'certification') {
					val = value;
				}
				return {
					value,
					label: `${val}`,
				};
			});
		}

		setOptions(result);
	}, [plots]);

	return options;
}

function mapDataTableData(row) {
	const r = clone(row);
	r['certification'] = r['certification'] ? 'Si' : 'No';
	return r;
}

function mapDataTableTitle(lang) {
	return (row) => {
		const [key] = row;
		const response = {
			name: capitalize(getPropertyTitle(key, lang).replace(/_/g, ' ')),
			selector: (row) => row[key],
			sortable: true,
		};
		if (key === 'avenue') {
			response.conditionalCellStyles = [
				{
					when: () => true,
					style: {},
				},
			];
		}
		return response;
	}
}

function capitalize(str) {
	const words = str.split(' ');

	for (let i = 0; i < words.length; i++) {
		words[i] = words[i][0].toUpperCase() + words[i].substr(1);
	}

	return words.join(' ');
}
