import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import './Maps.css';
import { onSectionLoad } from '../Events';
import { MapViewerComponent } from './MapViewer';

const styles = (theme) => ({
	title: {
		fontSize: 18,
		color: '#A5A4A4',
		marginTop: '5%',
		marginBottom: 50,
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: '33.33%',
		flexShrink: 0,
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
	},
	headerContainer: {
		flex: 1,
		flexDirection: 'row',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
});

function Maps(props) {
	useEffect(() => {
		onSectionLoad('maps');
	}, []);
	const { locale } = props;
	return (
		<div className="map-container">
			<MapViewerComponent lang={locale.locale}  />
		</div>
	);
}

function mapStateToProps({ settings: { locale } }) {
	return {
		locale,
	};
}

Maps.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(Maps));
