import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import IntMessage from '../lang/IntlMessages';
import DialogContent from '@material-ui/core/DialogContent';
import { CONTACT_TYPES } from '../constants/data';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { NotificationManager } from 'react-notifications';
import { sendFeedback } from '../services/feedback-service';

const INITIAL_STATE = {
	loading: false,
	errors: {},
	feedback: {
		content: '',
		email: '',
		lastName: '',
		name: '',
		phone: '',
		subject: '',
		type: '',
	},
};

class FeedbackContainer extends React.Component {
	state = { ...INITIAL_STATE };

	validateErrors = () => {
		let fields = Object.keys(this.state.feedback);

		let errors = 0;
		const showErrors = {};
		for (let value of fields) {
			showErrors[value] = true;
			if (!this.state.feedback[value]) {
				showErrors[value] = true;
				errors++;
			} else {
				showErrors[value] = false;
			}
		}
		this.setState({ ...this.state, errors: showErrors });

		if (errors > 0) {
			NotificationManager.error('Todos campos son requeridos');
			return false;
		}

		return true;
	};

	onChange = ({ value, name }) => {
		if (name) {
			this.setState({
				feedback: { ...this.state.feedback, [name]: value },
				errors: { ...this.state.errors, [name]: undefined },
			});
		}
	};

	submit = (e) => {
		e.preventDefault();
		if (!this.validateErrors()) return false;

		this.setState({ loading: true });
		sendFeedback(this.state.feedback)
			.then(() => {
				NotificationManager.success('Información enviada.');
				setOpen(false);
			})
			.catch(() => {
				NotificationManager.error(
					'No fue posible enviar su información, por favor, intente de nuevo más tarde.'
				);
			})
			.finally(() => this.setState({ ...INITIAL_STATE }));
		const { setOpen } = this.props;
		return false;
	};

	render() {
		const { open, setOpen } = this.props;

		return (
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				fullWidth
				maxWidth="sm"
				onClose={() => setOpen(false)}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle id="alert-dialog-slide-title">
					<IntMessage id="contact_us" />
				</DialogTitle>
				<DialogContent>
					<form onSubmit={this.submit}>
						<div className="form-group row">
							<label
								htmlFor="subject"
								className={
									'col-sm-4 col-form-label ' +
									(this.state.errors.subject
										? 'text-danger'
										: '')
								}
							>
								<IntMessage id="subject" />
								<span className="text-danger">*</span>
							</label>
							<div className="col-sm-8">
								<IntMessage id="subject">
									{(text) => (
										<input
											type="text"
											maxLength={100}
											className={
												'form-control ' +
												(this.state.errors.subject
													? 'is-invalid'
													: '')
											}
											value={this.state.feedback.subject}
											id="subject"
											name="subject"
											onChange={(e) =>
												this.onChange(e.target)
											}
											placeholder={text}
											required
										/>
									)}
								</IntMessage>
							</div>
						</div>
						<div className="form-group row">
							<label
								htmlFor="type"
								className={
									'col-sm-4 col-form-label ' +
									(this.state.errors.type
										? 'text-danger'
										: '')
								}
							>
								<IntMessage id="type" />
								<span className="text-danger">*</span>
							</label>
							<div className="col-sm-8">
								<select
									required
									id="type"
									name="type"
									value={this.state.feedback.type}
									onChange={(e) => this.onChange(e.target)}
									className={
										'form-control ' +
										(this.state.errors.type
											? 'is-invalid'
											: '')
									}
								>
									<IntMessage id="choose">
										{(text) => (
											<option value={''} disabled>
												{text}...
											</option>
										)}
									</IntMessage>
									{CONTACT_TYPES.map((value) => (
										<IntMessage key={value} id={value}>
											{(text) => (
												<option value={value}>
													{text}
												</option>
											)}
										</IntMessage>
									))}
								</select>
							</div>
						</div>
						<div className="form-group row">
							<label
								htmlFor="name"
								className={
									'col-sm-4 col-form-label ' +
									(this.state.errors.name
										? 'text-danger'
										: '')
								}
							>
								<IntMessage id="firstName" />
								<span className="text-danger">*</span>
							</label>
							<div className="col-sm-8">
								<IntMessage id="firstName">
									{(text) => (
										<input
											type="text"
											maxLength={100}
											className={
												'form-control ' +
												(this.state.errors.name
													? 'is-invalid'
													: '')
											}
											required
											value={this.state.feedback.name}
											id="name"
											name="name"
											onChange={(e) =>
												this.onChange(e.target)
											}
											placeholder={text}
										/>
									)}
								</IntMessage>
							</div>
						</div>
						<div className="form-group row">
							<label
								htmlFor="lastName"
								className={
									'col-sm-4 col-form-label ' +
									(this.state.errors.lastName
										? 'text-danger'
										: '')
								}
							>
								<IntMessage id="lastName" />
								<span className="text-danger">*</span>
							</label>
							<div className="col-sm-8">
								<IntMessage id="lastName">
									{(text) => (
										<input
											type="text"
											className={
												'form-control ' +
												(this.state.errors.lastName
													? 'is-invalid'
													: '')
											}
											required
											maxLength={100}
											value={this.state.feedback.lastName}
											id="lastName"
											name="lastName"
											onChange={(e) =>
												this.onChange(e.target)
											}
											placeholder={text}
										/>
									)}
								</IntMessage>
							</div>
						</div>
						<div className="form-group row">
							<label
								htmlFor="phone"
								className={
									'col-sm-4 col-form-label ' +
									(this.state.errors.phone
										? 'text-danger'
										: '')
								}
							>
								<IntMessage id="phone" />
								<span className="text-danger">*</span>
							</label>
							<div className="col-sm-8">
								<IntMessage id="phone">
									{(text) => (
										<input
											type="phone"
											className={
												'form-control ' +
												(this.state.errors.phone
													? 'is-invalid'
													: '')
											}
											required
											maxLength={100}
											value={this.state.feedback.phone}
											id="phone"
											name="phone"
											onChange={(e) =>
												this.onChange(e.target)
											}
											placeholder={text}
										/>
									)}
								</IntMessage>
							</div>
						</div>
						<div className="form-group row">
							<label
								htmlFor="email"
								className={
									'col-sm-4 col-form-label ' +
									(this.state.errors.email
										? 'text-danger'
										: '')
								}
							>
								<IntMessage id="email" />
								<span className="text-danger">*</span>
							</label>
							<div className="col-sm-8">
								<IntMessage id="email">
									{(text) => (
										<input
											type="email"
											className={
												'form-control ' +
												(this.state.errors.email
													? 'is-invalid'
													: '')
											}
											required
											maxLength={100}
											id="email"
											name="email"
											value={this.state.feedback.email}
											onChange={(e) =>
												this.onChange(e.target)
											}
											placeholder={text}
										/>
									)}
								</IntMessage>
							</div>
						</div>

						<div className="form-group row">
							<label
								htmlFor="content"
								className={
									'col-sm col-form-label ' +
									(this.state.errors.content
										? 'text-danger'
										: '')
								}
							>
								<IntMessage id="content" />
								<span className="text-danger">*</span>{' '}
							</label>
							<div className="col-sm-8">
								<textarea
									id="content"
									name="content"
									value={this.state.feedback.content}
									maxLength={100}
									onChange={(e) =>
										this.onChange({
											value: e.target.value,
											name: 'content',
										})
									}
									rows={4}
									className={
										'form-control ' +
										(this.state.errors.content
											? 'is-invalid'
											: '')
									}
								/>
							</div>
						</div>
					</form>
				</DialogContent>
				<DialogActions>
					<button
						type="button"
						disabled={this.state.loading}
						onClick={this.submit}
						className="btn btn-primary align-self-center"
					>
						<IntMessage id="accept" />
					</button>
				</DialogActions>
			</Dialog>
		);
	}
}

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

export default FeedbackContainer;
