import API from './http';

const PATH = 'purchasers/';


/**
 * Return purchase object by purchaser id and expireDate
 * @param id
 * @param expireDate
 * @returns {*}
 */
export const findPurchaserById =
    (id, expireDate) => API.get(`${PATH + id}?expireDate=${expireDate}`);

/**
 *
 * @param purchaser
 * @returns {Promise<AxiosResponse<any>>}
 */
export const save = (purchaser) => API.post(PATH, purchaser);

