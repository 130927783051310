import React, { useState, useContext, useEffect } from 'react';
import {
	GoogleMap,
	useJsApiLoader,
	Polygon,
	InfoWindow,
} from '@react-google-maps/api';

import './MapViewer.css';

import { MapViewerContext } from './MapViewer.context';
import { getPropertyTitle, useGetPlots } from '../App.hooks';

import { CENTER, ZOOM, GOOGLE_MAPS_OPTIONS } from '../App.constants';

// https://www.ultimateakash.com/blog-details/Ii1TPGAKYAo=/How-To-Integrate-Google-Maps-Drawing-Manager-in-React-2023#google_vignette

export function MapViewerComponent({ lang }) {
	const [position, setPosition] = useState(null);
	const [map, setMap] = useState(null);
	const [plot, setPlot] = useState(null);
	const { loading, error, plots } = useGetPlots();

	if (loading !== false) {
		return <div className="map-viewer loading">Loading</div>;
	}

	if (error) {
		return <div className="map-viewer error">{error.message}</div>;
	}

	return (
		<MapViewerContext.Provider
			value={{
				map,
				setMap,
				plots,
				plot,
				setPlot,
				position,
				setPosition,
				lang
			}}
		>
			<div className="map-viewer">
				<div className="wrapper">
					<MapComponent />
				</div>
				<Contact />
			</div>
		</MapViewerContext.Provider>
	);
}

function Contact() {
	const { lang } = useContext(MapViewerContext);
	return (
		<div className="contact">
			<div>
				<h3>{lang === 'en' ? 'Contact' : 'Contacto'}</h3>
				<h4>
					{lang === 'en' ? 'Office of the Executive Secretary of Colón Puerto Libre' : 'Oficina de la Secretaría Ejecutiva de Colón Puerto Libre'}
				</h4>
				<h5>Licda. Zajary Rodríguez</h5>
				<small>
					{lang === 'en' ? 'Email' : 'Correo'}:{' '}
					<a href="mailto:zajary.rodriguez@mici.gob.pa">
						Zajary.rodriguez@mici.gob.pa
					</a>
				</small>
				<small>
					{lang === 'en' ? 'Phone ' : 'Tel. '} <a href="tel:475-4706">475-4706</a>
				</small>
			</div>
		</div>
	);
}

function MapComponent() {
	const { plots, setPlot, setPosition } = useContext(MapViewerContext);

	const [polygons, setPolygons] = useState([]);
	const { isLoaded, loadError } = useJsApiLoader(GOOGLE_MAPS_OPTIONS);

	useEffect(() => {
		setPolygons(
			plots.filter((plot) => plot.path).map((plot) => [plot.path]),
		);
	}, [plots]);

	const containerStyle = {
		width: '100%',
		height: '100%',
	};

	const polygonOptions = {
		fillOpacity: 0.3,
		fillColor: '#ff0000',
		strokeColor: '#ff0000',
		strokeWeight: 2,
		draggable: false,
		editable: false,
	};

	const onClickPolygon = (event, index) => {
		const { latLng } = event;
		setPosition(latLng);
		const plot = plots[index];
		setPlot(plot);
	};

	if (loadError) {
		return (
			<div className={'map-container error'}>
				Error loading the map please refresh the page
			</div>
		);
	}

	console.log(`POLYGONS`, polygons)

	if (!polygons.length) {
		return null;
	}

	

	return isLoaded ? (
		<div className="map-container" style={{ position: 'relative' }}>
			<GoogleMap
				zoom={ZOOM}
				center={CENTER}
				mapContainerStyle={containerStyle}
			>
				{polygons.map((iterator, index) => (
					<Polygon
						key={index}
						id={`polygon-${index}`}
						onClick={(e) => onClickPolygon(e, index)}
						options={polygonOptions}
						paths={iterator}
					/>
				))}
				<InfoPlot />
			</GoogleMap>
		</div>
	) : null;
}

function InfoPlot() {
	const {
		plot,
		position,
		setPlot,
		setPosition,
		lang
	} = useContext(MapViewerContext);
	if (!plot) {
		return null;
	}
	if (!position) {
		return null;
	}

	const {
		plan,
		block,
		street,
		avenue,
		estate,
		property,
		lot,
		type,
		owner,
		condition,
		category,
		area,
		range,
		certification,
		areaCode,
		corrections,
	} = plot;

	return (
		<InfoWindow
			position={position}
			onUnmount={() => {
				setPlot(null);
				setPosition(null);
			}}
			onCloseClick={() => {
				setPlot(null);
				setPosition(null);
			}}
		>
			<div className="info-window">
				<div>
					<h3>{getPropertyTitle('plan', lang)}</h3>
					<p>{plan}</p>
				</div>
				<div>
					<h3>{getPropertyTitle('block', lang)}</h3>
					<p>{block}</p>
				</div>
				<div>
					<h3>{getPropertyTitle('street', lang)}</h3>
					<p>{street}</p>
				</div>
				<div>
					<h3>{getPropertyTitle('avenue', lang)}</h3>
					<p>{avenue}</p>
				</div>
				<div>
					<h3>{getPropertyTitle('corrections', lang)}</h3>
					<p>{corrections}</p>
				</div>
				{estate ? (
					<div>
						<h3>{getPropertyTitle('estate', lang)}</h3>
						<p>{estate}</p>
					</div>
				) : null}
				{property ? (
					<div>
						<h3>{getPropertyTitle('property', lang)}</h3>
						<p>{property}</p>
					</div>
				) : null}
				<div>
					<h3>{getPropertyTitle('lot', lang)}</h3>
					<p>{lot}</p>
				</div>
				<div>
					<h3>{getPropertyTitle('type', lang)}</h3>
					<p>{type}</p>
				</div>
				<div>
					<h3>{getPropertyTitle('owner', lang)}</h3>
					<p>{owner}</p>
				</div>
				<div>
					<h3>{getPropertyTitle('condition', lang)}</h3>
					<p>{condition}</p>
				</div>
				<div>
					<h3>{getPropertyTitle('category', lang)}</h3>
					<p>{category}</p>
				</div>
				{area ? (
					<div>
						<h3>{getPropertyTitle('area', lang)}</h3>
						<p>{area}</p>
					</div>
				) : null}

				<div>
					<h3>{getPropertyTitle('range', lang)}</h3>
					<p>{range}</p>
				</div>
				<div>
					<h3>{getPropertyTitle('certification', lang)}</h3>
					<p>{certification ? (lang === 'es' ? 'Si' : 'Yes') : 'No'}</p>
				</div>
				{areaCode ? (
					<div>
						<h3>{getPropertyTitle('plan', lang)}</h3>
						<p>{certification}</p>
					</div>
				) : null}
			</div>
		</InfoWindow>
	);
}
