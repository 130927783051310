import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Carousel from 'react-bootstrap/Carousel';

import img1 from './slider/1.jpg';
import img2 from './slider/2.jpg';
import img3 from './slider/3.jpg';
import img4 from './slider/4.jpg';
import img5 from './slider/5.jpg';

const styles = () => ({
	caption: {},
	container: {
		backgroundColor: '#000',
	},
});

const homeHeader = ({ classes }) => {
	const carouselItems = [
		{
			title: '',
			description: '',
			image: img1,
			altImage: '',
		},
		{
			title: '',
			description: ``,
			image: img2,
			altImage: '',
		},
		{
			title: '',
			description: '',
			image: img3,
			altImage: '',
		},
		{
			title: '',
			description: ``,
			image: img4,
			altImage: '',
		},
		{
			title: '',
			description: ``,
			image: img5,
			altImage: '',
		},
	];

	return (
		<Carousel className={classes.container}>
			{carouselItems.map((item, i) => {
				return <Carousel.Item key={i} className="carousel-item-container">
					<img
						className={classes.image}
						src={item.image}
						alt={item.altImage}
					/>
					<Carousel.Caption className="carousel-caption-container">
						<div
							className={`${classes.caption} carousel-slider-caption`}
						>
							<h2>{item.title}</h2>
							<h5>{item.description}</h5>
						</div>
					</Carousel.Caption>
				</Carousel.Item>;
			})}
		</Carousel>
	);
};
export default withStyles(styles)(homeHeader);
