import React from 'react';
import Banner from "./Banner";

export default () => {
    return (
        <div>
            <Banner />
            <RegulatoryFrameworkES />
        </div>
    );
}

const RegulatoryFrameworkES = () => {
    return <div>
        <h1>MARCO NORMATIVO</h1>
        <p>
            El marco normativo del Sistema Especial de Colón Puerto Libre comprende las siguientes excertas legales:
        </p>

        <h2>LEYES</h2>
        <ul>
            <li>
                <b>Ley No.29 de 30 de diciembre de 1992</b>: Por la cual se adopta un sistema especial de Puerto Libre para la provincia de Colón y se dicta otras medidas
            </li>
            <li>
                <b>Ley No.7 de 4 de abril de 2016</b>: Que modifica y adiciones artículos a la Ley 29 de 1992, que adopta un sistema especial de Puerto Libre para la provincia de Colón, para su efectiva implementación y funcionamiento
            </li>
            <li>
                <b>Ley No. 60 de 5 de noviembre de 2018</b>: Que reforma la Ley 29 de 1992, que adopta un Sistema Especial de Puerto Libre para la provincia de Colón
            </li>
        </ul>

        <h2>DECRETOS</h2>
        <ul>
            <li>
                <b>Decreto Ejecutivo No.86 de 4 de agosto de 2016</b>: Que designa la Junta Asesora del Sistema Especial de Puerto Libre para la provincia de Colón
            </li>
            <li>
                <b>Decreto Ejecutivo No.87 de 4 de agosto de 2016</b>: Que crea la Secretaría del Sistema Especial de Puerto Libre para la provincia de Colón
            </li>
            <li>
                <b>Decreto Ejecutivo No.131 de 4 de octubre de 2016</b>: Que incorpora productos o mercancías sujetas al sistema fiscal y aduanero especial de Puerto Libre para la provincia de Colón
            </li>
            <li>
                <b>Decreto Ejecutivo No. 84 de 30 de junio 2017</b>: Que incorpora productos o mercancías sujetas al sistema Fiscal y aduanero especial Puerto Libre.
            </li>
        </ul>

    </div>
};

