module.exports = {
	administration: 'Administración',
	resolution: 'Resolución',
	home: 'Inicio',
	information: 'Información',
	purchasers: 'Compradores',
	merchants: 'Comercios',
	contact_us: 'Contáctanos',
	step: 'Paso',

	link_of_interest: 'Enlaces de interés',
	contact: 'Contacto',
	followUs: 'Siguenos',
	termsConditions: 'Términos y condiciones',
	privacyPolicies: 'Políticas de privacidad',
	rightReservedText:
		'Derechos de Autor @ CrimsonLogic Pte Ltd 2009. Todos los Derechos reservados. Ninguna porción de este sitio web podrá ser usado o reproducido sin el previo consentimiento por escrito de CrimsonLogic Pte Ltd.CrimsonLogic y el logo de CrimsonLogic son marcas registradas de CrimsonLogic Pte Ltd. ',
	about_us: 'Acerca de nosotros',
	announcements: 'Anuncios',
	published: 'Publicado',
	events: 'Eventos',
	news: 'Noticias',
	dniNumber: 'Nº identificación',
	enter_id_number: 'ingrese Nº identificación',
	birthdate: 'Fecha de nacimiento',
	expirationDate: 'Fecha de expiración de documento',
	purchaserType: 'Tipo de comprador',
	process: 'Tipo de comprador',
	search: 'Buscar',
	searching: 'Buscando',
	consult: 'Consulta',
	signUp: 'Registrate',

	dniNames: 'Nº Cédula / Pasaporte',
	names: 'Nombres',
	firstName: 'Nombre',
	lastName: 'Apellido',
	sex: 'Género',
	choose: 'Selecciona',
	male: 'Hombre',
	female: 'Mujer',
	docType: 'Tipo de documento',
	Resident: 'Residente (Pasaporte)',
	Citizen: 'Panameño / Residente (Cédula)',
	Tourist: 'Turista',
	plasticNumber: 'Número del plástico',
	arrivalDate: 'Fecha de llegada',
	lengthOfStay: 'Duración de la estadía ',
	durationInDays: 'Duración en días',
	countryOfBirth: 'País de nacimiento',
	address: 'Dirección',

	// Purchaser Details Interface
	purchaserDetails: 'Detalle de comprador',
	createdDate: 'Fecha de inscripción',
	taxFreeAmountSpent: 'Montos libres de impuestos gastados',
	familyPurchaseTransferred: 'Transferencia familiar',
	purchaseAmount: 'Disponible libre de impuestos',
	expirationBalance: 'Fecha de vencimiento del saldo disponible',
	lastPurchase: 'Última compra',
	lastPurchaseDate: 'fecha',
	purchaser_not_found: 'Comprador no encontrado',
	back: 'Regresar',
	Cedula: 'Cédula',
	Passport: 'Pasaporte',
	close: 'Cerrar',

	// information
	historical_review: 'Reseña Histórica',
	mission_vision: 'Misión/Visión',
	strategic_objectives: 'Objetivos Estratégicos',
	regulatory_framework: 'Marco Normativo',
	organization_chart: 'Organigrama',
	authorities: 'Autoridades',
	advisory_board: 'Junta Asesora',

	// languages
	languages: 'Idiomas',
	spanish: 'Español',
	english: 'Ingles',
	loading: 'Cargando',

	commercial_register: 'Registro de comercio',
	business_name: 'Razon social',
	phone: 'Telefono',
	ruc: 'RUC',
	email: 'Correo Electrónico',
	legal_representative: 'Representante legal',
	nationality: 'Nacionalidad',
	type_of_register: 'Tipo de registro',
	importers: 'Importadores',
	infrastructure: 'Infraestructura',
	merchant: 'Comerciante',
	established_in_ZNC: '¿Establecido en ZLC?',
	yes: 'Si',
	no: 'No',
	operationsKey: 'Clave de operaciones',
	activityDescription: 'Actividades a desarrollar',
	productsDescription: 'Productos que manejara',
	import_countries: 'Países de importación',
	employees: 'Número de empleos a generar',
	observations: 'Observaciones',
	documents: 'Documentos',
	maps: 'Mapas',
	notice_operations: 'Aviso de operaciones',
	peace_save: 'Paz y salvo vigente',
	legal_representative_id: 'Identificación del representante legal',
	public_record_certificate: 'Certificado del registro público',
	affidavit_legal_representative:
		'Declaración jurada del representante legal',
	hiring_commitment_proposal: 'Propuesta de compromiso de compra',
	cancel: 'Cancelar',
	save: 'Guardar',
	saving: 'Guardando',
	request_number: 'Número de solicitud',
	next: 'Siguiente',
	COMMERCE_REQUEST_NOT_FOUND: 'Número de solicitud no encontrada',
	state: 'Estado',
	request: 'Solicitud',
	date: 'Fecha',

	// status
	pending: 'Pendiente',
	viewed: 'Vista',
	acknowledge: 'Admitida',
	rejected: 'Rechazada',
	documentsTitle: 'Documentos e información relevante',
	accept: 'Aceptar',
	successfulOperation: 'Operación exitosa',
	merchant_request_number_message:
		'Usa este número para consultar en cualquier momento el estado de su solicitud',
	empty: 'Vacio',
	thumbnail: 'Miniatura',
	cover: 'Portada',
	subject: 'Tema',
	type: 'Tipo',
	content: 'Contenido',
	suggest: 'Suggest',
	feedback: 'Comentario',
	congratulations: 'Felicitaciones',

	what_we_do: '¿QUÉ ES COLÓN PUERTO LIBRE?',
	what_we_do_content:
		'Es el Sistema Especial de Puerto Libre para la Provincia de Colón que se implementa dentro de las 16 calles del Casco Antiguo de la ciudad de Colón, incluyendo el área de Colón 2000, ofreciendo incentivos fiscales y aduaneros para los inversionistas comerciantes, importadores y de infraestructura, así como compras libres de impuestos para los panameños, extranjeros residentes y turistas.',
	what_can_you_buy: '¿QUÉ PRODUCTOS PUEDES ADQUIRIR?',
	what_can_you_buy_content:
		'Tecnología | Ropa |Artículos de bebé |Bolsas y maletines | Artículos de belleza | Juguetes | Suplementos de oficina |Artículos deportivos | Artículos domésticos | Confitería | Artículos musicales |Artículos navideños | Calzados | Joyería | Perfumería | Cosméticos',
	requirements_title: 'REQUISITOS',
	requirements_trading_title: 'INVERSIONISTAS COMERCIANTES/IMPORTADORES',
	requirements_trading_content_1:
		'Fotocopia de la cédula o del representante legal de la sociedad anónima.',
	requirements_trading_content_2: 'Aviso de Operación.',
	requirements_trading_content_3:
		'Certificado de Registro Público (original o copia autenticada) con vigencia no mayor de tres (3) meses. En caso de persona jurídica.',
	requirements_trading_content_4:
		'Declaración Jurada que certifique beneficiario final (accionistas).',
	requirements_trading_content_5:
		'Declaración Jurada debidamente notariada que indique e el compromiso de contratación de mano de obra colonense (si el inversionista es persona natural debe firmar la persona, o el representante legal si es una sociedad anónima).',
	requirements_trading_content_6:
		'Copia de Contrato de Arrendamiento refrendado por el MIVIOT si el local es alquilado, y si el local es propio, deberá presentar una Certificación del Registro Público de la propiedad.',
	requirements_trading_content_7:
		'Formulario de Registro en el Sistema Especial de Colón Puerto Libre',
	requirements_investors_title: 'INVERSIONISTAS DE INFRAESTRUCTURA',
	requirements_investors_content_1:
		'Fotocopia de la cédula de identificación personal o pasaporte, según sea el caso, del propietario del inmueble.',
	requirements_investors_content_2:
		'Certificado del Registro Público del inmueble (Original o copia autentica) con vigencia no mayor de (3) meses.',
	requirements_investors_content_3:
		'Si el propietario es persona Jurídica: Certificado del Registro Público (Original o copia autentica) con vigencia no mayor de (3) meses.',
	requirements_investors_content_4:
		'Certificación de uso de suelo emitido por el Ministerio de Vivienda y Ordenamiento - Territorial MIVIOT (descargar formulario de página web de MIVIOT)',
	requirements_investors_content_5:
		'Declaración Jurada Notariada que indique el compromiso de contratación de mano de obra colonense, firmada por el representante legal.',
	requirements_investors_content_6:
		'Formulario de Registro en el Sistema Especial de Colón Puerto Libre.',
	incentives_title: 'INCENTIVOS',
	incentives_investors_title: 'PARA INVERSIONISTAS DE INFRAESTRUCTURA',
	incentives_investors_content_1:
		'0% de impuesto de importación para equipo y material utilizado en remodelación',
	incentives_investors_content_2:
		'0% de impuesto de transferencia de bien inmueble por 2 años',
	incentives_investors_content_3: '0% de impuestos de inmuebles por 30 años.',
	incentives_investors_content_4:
		'0% de ISR por 10 años desde permiso de ocupación.',
	incentives_investors_content_5:
		'Crédito fiscal aplicable para financiamientos',
	incentives_merchants_title: 'PARA COMERCIANTES E IMPORTADORES',
	incentives_merchants_content_1:
		'Exoneración de impuesto de importación para equipo y material utilizado para sus operaciones (menos automóviles)',
	incentives_merchants_content_2:
		'Exoneración de impuesto de capital o de aviso de operación.',
	incentives_merchants_content_3:
		'Créditos fiscales sobre mercancías comercializadas en Colón Puerto Libre, las cuales hayan sido previamente nacionalizadas.',
	benefits_title: 'BENEFICIOS',
	benefits_consumers_title: 'PARA CONSUMIDORES',
	benefits_consumers_content:
		'Para turistas o pasajeros en tránsito: 0% de impuesto selectivo al consumo | 0% ITBMS | Compras con garantias de las mercancias y sin tope de beneficio de compras',
	benefits_panamenians_title: 'PANAMEÑOS Y EXTRANJEROS RESIDENTES',
	benefits_panamenians_content:
		'0% de Impuesto selectivo al consumo, 0% ITBMS, este beneficio se otorga a compras semestrales hasta por la suma de $ (1,000.00) Balboas y $(2,000.00) anuales con garantías de mercancías.',

	// Footer
	addresses: 'Direcciones',
	address_1:
		'Piso 3, Edison Plaza, Avenida Ricardo J. Alfaro Ciudad de Panamá, República de Panamá',
	address_2:
		'Local D-209, Millenium Plaza, Avenida Ahmad "Miguel" Waked Ciudad de Colón, República de Panamá',

	lang: 'es',
};
