module.exports = {
	administration: 'Administration',
	resolution: 'Resolution',
	home: 'Home',
	information: 'Information',
	purchasers: 'Purchasers',
	merchants: 'Merchants',
	contact_us: 'Contact us',

	link_of_interest: 'Links of interest',
	contact: 'Contact',
	followUs: 'Follow us',
	termsConditions: 'Terms and Conditions',
	privacyPolicies: 'Privacy policies',
	rightReservedText:
		'Copyright @ CrimsonLogic Pte Ltd 2009. All rights reserved. No portion of this website may be used or reproduced without the prior written consent of CrimsonLogic Pte Ltd. CrimsonLogic and the CrimsonLogic logo are registered trademarks of CrimsonLogic Pte Ltd.',
	about_us: 'About us?',
	announcements: 'Announcements',
	published: 'Published',
	events: 'Events',
	news: 'News',
	dniNumber: 'Identification number',
	enter_id_number: 'Identification number',
	birthdate: 'Birthdate',
	expirationDate: "Document's expiration date",
	search: 'Search',
	searching: 'Searching',
	consult: 'Consult',
	signUp: 'Sign up',
	dniNames: 'DNI / Passport',
	names: 'Names',
	firstName: 'first name',
	lastName: 'last name',
	sex: 'Gender',
	choose: 'Choose',
	male: 'Male',
	female: 'Female',
	docType: 'Doc type',
	Resident: 'Foreigner resident',
	Citizen: 'Panamanian  resident',
	Tourist: 'Tourist',
	plasticNumber: 'Plastic number',
	arrivalDate: 'arrival date',
	lengthOfStay: 'length of stay',
	durationInDays: 'Duration in days',
	purchaserType: 'Purchaser type',
	countryOfBirth: 'Country of birth',
	address: 'Country of birth',

	// Purchaser Details Interface
	purchaserDetails: 'Purchaser details',
	createdDate: 'Created date',
	taxFreeAmountSpent: 'Tax-free amounts spent',
	familyPurchaseTransferred: 'Family Purchase Transferred',
	purchaseAmount: 'Tax free available',
	expirationBalance: 'Expiration date of available balance',
	lastPurchase: 'Last purchase',
	lastPurchaseDate: 'Last purchase date',
	purchaser_not_found: 'Purchaser  not found',
	Cedula: 'DNI',
	Passport: 'Passport',
	close: 'Close',

	// information
	historical_review: 'Historical Review',
	mission_vision: 'Mission/Vision',
	strategic_objectives: 'Strategic Objectives',
	regulatory_framework: 'Regulatory Framework',
	organization_chart: 'Organization Chart',
	authorities: 'Authorities',
	advisory_board: 'Advisory Board',

	// languages
	languages: 'Languages',
	spanish: 'Spanish',
	english: 'English',
	loading: 'Loading',

	commercial_register: 'Commercial register',
	business_name: 'Business name',
	phone: 'Phone',
	ruc: 'RUC',
	email: 'Email',
	legal_representative: 'Legal representative',
	nationality: 'Nationality',
	type_of_register: 'Type of register',
	infrastructure: 'Infrastructure',
	importers: 'Importers',
	merchant: 'Merchant',
	established_in_ZNC: 'Established in ZLC?',
	yes: 'Yes',
	no: 'No',
	operationsKey: 'Transaction key',
	activityDescription: 'Activities to develop',
	productsDescription: 'Products you will manage',
	import_countries: 'Import countries',
	employees: 'Employees',
	observations: 'Observations',
	documents: 'Documents',
	maps: 'Maps',
	step: 'Step',
	notice_operations: 'Notice of Operations',
	peace_save: 'Peace and save',
	legal_representative_id: 'Legal representative id',
	public_record_certificate: 'Public record certificate',
	affidavit_legal_representative: 'Affidavit legal representative',
	hiring_commitment_proposal: 'Hiring commitment proposal',
	cancel: 'Cancel',
	save: 'Save',
	saving: 'Saving',
	request_number: 'Request number',
	next: 'Next',
	back: 'Back',
	COMMERCE_REQUEST_NOT_FOUND: 'Commerce request not found',
	state: 'State',
	request: 'Request',
	date: 'Date',
	// status
	pending: 'Pending',
	viewed: 'Viewed',
	acknowledge: 'Acknowledge',
	rejected: 'Rejected',
	documentsTitle: 'Documents and relevant information',
	accept: 'Accept',
	successfulOperation: 'Successful operation',
	merchant_request_number_message:
		'Use this number to consult the status of your request at any time',
	empty: 'Empty',
	thumbnail: 'Thumbnail',
	cover: 'Cover',
	subject: 'Subject',
	type: 'Type',
	content: 'Content',
	suggest: 'Suggest',
	feedback: 'Feedback',
	congratulations: 'Congratulations',

	what_we_do: 'WHAT IS COLÓN PUERTO LIBRE?',
	what_we_do_content:
		'It is the Special System of Free Port for the Province of Colón that is implemented within the 16 streets of the Old Town of the city of Colón, including the area of ​​Colón 2000, offering tax and customs incentives for merchant, import and infrastructure investors , as well as duty-free purchases for Panamanians, resident foreigners and tourists.',
	what_can_you_buy: 'WHAT PRODUCTS CAN YOU PURCHASE?',
	what_can_you_buy_content:
		'Technology | Clothing | Baby items | Bags and briefcases | Beauty items | Toys | Office supplements | Sporting goods | Household items | Confectionery | Musical articles Christmas articles | Footwear | Jewelry store | Perfumery | Cosmetics',
	requirements_title: 'REQUIREMENTS',
	requirements_trading_title: 'TRADING / IMPORTANT INVESTORS',
	requirements_trading_content_1:
		'Photocopy of the identity card or the legal representative of the corporation.',
	requirements_trading_content_2: 'Notice of Operation.',
	requirements_trading_content_3:
		'Public Registry Certificate (original or authenticated copy) valid for no more than three (3) months. In the case of a legal entity.',
	requirements_trading_content_4:
		'Affidavit certifying the final beneficiary (shareholders).',
	requirements_trading_content_5:
		'Affidavit duly notarized indicating the commitment to hire Colonense labor (if the investor is a natural person must sign the person, or the legal representative if it is a corporation).',
	requirements_trading_content_6:
		'Copy of the Leasing Contract endorsed by MIVIOT if the premises are rented, and if the premises are their own, they must present a Certification from the Public Registry of the property.',
	requirements_trading_content_7:
		'Registration Form in the Colon Free Port Special System',
	requirements_investors_title: 'INFRASTRUCTURE INVESTORS',
	requirements_investors_content_1:
		'Photocopy of the personal identification card or passport, as the case may be, of the owner of the property.',
	requirements_investors_content_2:
		'Certificate from the Public Registry of the property (Original or authentic copy) valid for no more than (3) months.',
	requirements_investors_content_3:
		'If the owner is a legal person: Certificate from the Public Registry (Original or authentic copy) valid for no more than (3) months.',
	requirements_investors_content_4:
		'Land use certification issued by the Ministry of Housing and Planning - Territorial MIVIOT (download form from MIVIOT website)',
	requirements_investors_content_5:
		'Notarized Sworn Declaration indicating the commitment to contract labor from Colón, signed by the legal representative.',
	requirements_investors_content_6:
		'Registration Form in the Colon Free Port Special System',
	incentives_title: 'INCENTIVES',
	incentives_investors_title: 'INCENTIVES FOR INFRASTRUCTURE INVESTORS',
	incentives_investors_content_1:
		'0% import tax for equipment and material used in remodeling',
	incentives_investors_content_2: '0% real estate transfer tax for 2 years',
	incentives_investors_content_3: '0% real estate taxes for 30 years.',
	incentives_investors_content_4:
		'0% of ISR for 10 years from occupancy permit.',
	incentives_investors_content_5: 'Applicable tax credit for financing',
	incentives_merchants_title: 'FOR MERCHANTS AND IMPORTERS',
	incentives_merchants_content_1:
		'Import tax exemption for equipment and material used for its operations (less automobiles)',
	incentives_merchants_content_2:
		'Exemption from capital tax or notice of operation.',
	incentives_merchants_content_3:
		'Tax credits on merchandise sold in Colon Free Port, which have been previously nationalized.',
	benefits_title: 'BENEFITS',
	benefits_consumers_title: 'FOR CONSUMERS',
	benefits_consumers_content:
		'For tourists or passengers in transit: 0% selective consumption tax | 0% ITBMS | Unlimited purchases',
	benefits_panamenians_title: 'PANAMENIANS AND FOREIGNERS RESIDENTS',
	benefits_panamenians_content:
		'0% Selective Consumption Tax, 0% ITBMS, this benefit is granted to semi-annual purchases up to the sum of $ (1,000.00) Balboas and $ (2,000.00) annually with merchandise guarantees.',

	// Footer
	addresses: 'Addresses',
	address_1:
		'Floor 3, Edison Plaza, Ricardo J. Alfaro Avenue, Panama City, Republic of Panama',
	address_2:
		'Local D-209, Millenium Plaza, Ahmad "Miguel" Waked Avenue, Colon City, Republic of Panama',

	lang: 'en',
};
