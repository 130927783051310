import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconUpload from '@material-ui/icons/CloudDownload'

import {connect} from "react-redux";
import IntlMessages from "../lang/IntlMessages";
import {getAllDocuments, getDownloadUrl} from "../services/document-service";
import {onSectionLoad} from './Events';

const styles = theme => ({
    title: {
        fontSize: 18,
        color: '#A5A4A4',
        marginTop: '5%',
        marginBottom: 50,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    headerContainer: {
        flex: 1,
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    }
});


class Documents extends Component {
    state = {
        expanded: null,
        documents: []
    };

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };


    componentDidMount() {
        getAllDocuments().then(documents => this.setState({documents}));
    }

    render() {
        onSectionLoad('document');
        const {classes, locale: {locale: icon}} = this.props;
        const {expanded} = this.state;

        return (
            <div className="container">
                <Typography variant="h6" color="textPrimary" className={classes.title} gutterBottom>
                    <IntlMessages id="documentsTitle"/>
                </Typography>

                {this.state.documents.map((document) => (
                    <ExpansionPanel key={document.id} expanded={expanded === document.id}
                                    onChange={this.handleChange(document.id)}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                            <div className={classes.headerContainer}>
                                <Typography className={classes.heading}>{document.title[icon]} </Typography>
                                <a href={getDownloadUrl(document.id)} style={{color: '#37ACE1'}} target="_blank"
                                   rel="noopener noreferrer" className='btn btn-sm '>
                                    <IconUpload/>
                                </a>
                            </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                {document.description[icon]}
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                ))}
                {(!this.state.documents || this.state.documents.length === 0) && (
                    <Typography variant="body1" color="textPrimary" className={classes.title} gutterBottom>
                        <IntlMessages id="empty"/>
                    </Typography>
                )}
            </div>
        );
    }
}

function mapStateToProps({settings: {locale}}) {
    return {
        locale
    }
}


Documents.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(Documents));
